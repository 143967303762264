class Masks {
  cpf(value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  cnpj(value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{2})$/, '$1-$2');
  }
  fone(value) {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d{4})(\d{4})$/, '$1-$2');
  }
  cep(value) {
    return value.replace(/\D/g, '').replace(/(\d{5})(\d{3})$/, '$1-$2');
  }
  valor(value) {
    return value.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1,$2');
  }
  valorFloat(value) {
    return value.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1.$2');
  }
}

export default new Masks();
