import React, { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import api from '../../../../../services/api';
import {
  Container,
  Titulo,
  Corpo,
  CorpoForm,
  CorpoFormButton,
  CorpoLinha,
  CorpoLinhaCampo,
  Input,
  Label,
} from '../../../../../styles/cadastro';
import Select from '../../../../../components/Select';
import selects from '../../../../../lib/selects';
import forms from '../../../../../lib/forms';
import masks from '../../../../../lib/masks';
import { Confirmation } from '../../../../../styles/confirmation';
import history from '../../../../../services/history';

export default function PropostaItemCadastro() {
  // States
  const [id, setId] = useState();
  const [tipopreco, setTipoPreco] = useState();
  // Entidade cadastrada
  const [entidade, setEntidade] = useState({
    proposta_id: null,
    item_id: null,
    itemdescricao: null,
    unidade: null,
    quantidade: null,
    valorunitario: null,
    valordesconto: null,
    valortotal: null,
  });
  const [entidadeValidacao, setEntidadeValidacao] = useState({
    proposta_id: true,
    item_id: true,
    itemdescricao: true,
    unidade: true,
    quantidade: true,
    valorunitario: true,
    // valordesconto: false,
    valortotal: true,
  });
  // Selects
  const [produtos, setProdutos] = useState([]);
  const [gra, setGra] = useState(true);

  // Form Show
  useEffect(() => {
    // Pega o ID quando edicao
    let i = [];
    let p = [];
    let aux = [];
    const urn = window.location.href.split('?');
    if (urn[1] !== undefined) {
      aux = urn[1].split('&');
      i = aux[0].split('=');
      p = aux[1].split('=');
    }

    // Pega o tipo do preco que sera considerado na inclusao de produtos
    setTipoPreco(p[1]);

    // Pega a proposta ou o id (inclusao ou alteracao)
    if (i[0] === 'proposta') {
      setEntidade({
        proposta_id: i[1],
        item_id: null,
        itemdescricao: null,
        unidade: null,
        quantidade: 1,
        valorunitario: 0,
        valordesconto: 0,
        valortotal: 0,
      });
    } else {
      async function popularEntidade() {
        let e = await api.get(`propostaitem/${i[1]}`);
        e.data.quantidade = parseFloat(e.data.quantidade).toFixed(2);
        e.data.valorunitario = masks.valor(e.data.valorunitario);
        e.data.quantidade = masks.valor(e.data.quantidade);
        e.data.valortotal = masks.valor(e.data.valortotal);
        e.data.valordesconto = masks.valor(e.data.valordesconto);
        setEntidade(e.data);
      }
      popularEntidade();
      setId(i[1]);
    }
    // Faz a consulta dos campos de select
    popularSelects();
  }, []); // eslint-disable-next-line

  // Popula todos o campos selects
  async function popularSelects() {
    const p = await api.get('/item');
    setProdutos(await selects.ajustaColunasList(p.data, 'id', 'descricao'));
  }

  // Grava o cadastro, fecha formulario se for edicao
  async function gravar(e) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();

    if (gra === true) {
      setGra(false);
      // Valida os campos obrigatorios
      const [o, v] = forms.validaFormulario(entidade, entidadeValidacao);
      setEntidadeValidacao(o);
      if (!v) {
        setGra(true);
        return false;
      }
      // Valida os valores
      const desc =
        entidade.valordesconto !== null && entidade.valordesconto !== ''
          ? parseFloat(entidade.valordesconto.replace(',', '.'))
          : 0;
      if (
        desc >
        parseFloat(entidade.quantidade.replace(',', '.')) *
          parseFloat(entidade.valorunitario.replace(',', '.'))
      ) {
        toast.error('VALOR DO DESCONTO É MAIOR QUE TOTAL DA VENDA');
        setGra(true);
        return false;
      }
      //
      let ent = entidade;
      // Ajusta campos numericos
      ent.quantidade = parseFloat(masks.valorFloat(ent.quantidade));
      ent.valorunitario = parseFloat(masks.valorFloat(ent.valorunitario));
      ent.valortotal = parseFloat(masks.valorFloat(ent.valortotal));
      ent.valordesconto !== null && ent.valordesconto !== ''
        ? (ent.valordesconto = parseFloat(masks.valorFloat(ent.valordesconto)))
        : (ent.valordesconto = 0);
      // Limpa campos em branco
      ent = forms.preparaFormulario(ent);
      // Editar
      if (id !== undefined) {
        const response = await api.put(`propostaitem/${id}`, ent);
        if (response.status === 200) {
          toast.success('PRODUTO EDITADO COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO EDITAR O PRODUTO');
          setGra(true);
        }
      }
      // Incluir
      else {
        const response = await api.post('propostaitem', ent);
        if (response.status === 200) {
          // toast.success('PRODUTO ADICIONADO COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO ADICIONAR O PRODUTO');
          setGra(true);
        }
      }
      // Editar total de produto e total da proposta
      let total = await api.get(`/total/propostaitem/${ent.proposta_id}`);
      const totalProduto = total.data.length === 0 ? 0 : total.data[0].total;
      total = await api.get(`proposta/${ent.proposta_id}`);
      const totalOutros = total.data.length === 0 ? 0 : total.data.valoroutros;
      const json = {
        valorproduto: parseFloat(totalProduto),
        valortotal: parseFloat(totalProduto) + parseFloat(totalOutros),
        importar: 1,
      };
      await api.put(`/ajuste/proposta/${ent.proposta_id}`, json);
      //
      history.push(`propostas-cadastro?id=${ent.proposta_id}`);
    }
  }

  // Cancela o cadastro e fecha o formulario
  async function cancelar(e) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Confirma o cancelamento do cadastro?</p>
            <div>
              <button
                onClick={async () => {
                  toast.warn('CADASTRO CANCELADO');
                  history.push(`propostas-cadastro?id=${entidade.proposta_id}`);
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
  }

  // Digita o campo no objeto e popula com seu devido valor
  async function digitarSelect(e) {
    if (e === null) {
      setEntidade(forms.populaFormulario(entidade, this.name, null));
      setEntidade(forms.populaFormulario(entidade, 'quantidade', 1));
      setEntidade(forms.populaFormulario(entidade, 'valorunitario', null));
      setEntidade(forms.populaFormulario(entidade, 'valordesconto', null));
      setEntidade(forms.populaFormulario(entidade, 'valortotal', null));
    } else {
      if (e.id !== undefined) {
        let ent = entidade;
        // item
        ent = forms.populaFormulario(ent, 'item_id', e.id);
        const response = await api.get(`item/${e.id}`);
        // quantidade
        ent = forms.populaFormulario(ent, 'quantidade', '1,00');
        // unidade
        ent = forms.populaFormulario(
          ent,
          'unidade',
          response.data.unidadevenda
        );
        // item descricao
        ent = forms.populaFormulario(
          ent,
          'itemdescricao',
          response.data.descricao
        );
        // valor unitario
        ent = forms.populaFormulario(
          ent,
          'valorunitario',
          tipopreco === '1'
            ? response.data.precoavista !== null
              ? masks.valor(response.data.precoavista)
              : '0,00'
            : response.data.precovenda !== null
            ? masks.valor(response.data.precovenda)
            : '0,00'
        );
        // desconto
        ent = forms.populaFormulario(ent, 'valordesconto', '0,00');
        // total
        ent = forms.populaFormulario(
          ent,
          'valortotal',
          parseFloat(
            parseFloat(masks.valorFloat(ent.quantidade)) *
              parseFloat(masks.valorFloat(ent.valorunitario))
          ).toFixed(2)
        );
        // popula entidade
        setEntidade(ent);
      }
    }
  }
  function digitarInput(e) {
    let valor;
    switch (e.target.name) {
      case 'quantidade':
        valor = masks.valor(e.target.value);
        break;
      case 'valorunitario':
        valor = masks.valor(e.target.value);
        break;
      case 'valordesconto':
        valor = masks.valor(e.target.value);
        break;
      case 'valortotal':
        valor = masks.valor(e.target.value);
        break;
      default:
        valor = e.target.value;
    }
    let ent = entidade;
    ent = forms.populaFormulario(ent, e.target.name, valor);
    let total = 0;

    if (parseFloat(masks.valorFloat(ent.valordesconto)) > 0) {
      total =
        parseFloat(masks.valorFloat(ent.quantidade)) *
          parseFloat(masks.valorFloat(ent.valorunitario)) -
        parseFloat(masks.valorFloat(ent.valordesconto));
    } else {
      total =
        parseFloat(masks.valorFloat(ent.quantidade)) *
        parseFloat(masks.valorFloat(ent.valorunitario));
    }

    ent = forms.populaFormulario(
      ent,
      'valortotal',
      total > 0 ? total.toFixed(2) : 0
    );
    setEntidade(ent);
  }

  // Faz a validacao no exit do campo
  function campoExit(campo) {
    setEntidadeValidacao(
      forms.validaFormularioCampo(entidade, entidadeValidacao, campo)
    );
  }

  // Escreve a label conforme parametros de validacao
  function campoLabel(label, field) {
    return `
      ${label}
      ${!entidadeValidacao[field] ? ' (Campo obrigatório)' : ''}
      `;
  }

  // Renderiza o codigo em tela
  return (
    <Container>
      <Titulo>
        <strong>CADASTRO DE ITENS DE PROPOSTAS</strong>
      </Titulo>
      <Corpo>
        <CorpoForm autoComplete="off" onSubmit={gravar}>
          <CorpoLinha>
            <CorpoLinhaCampo size={100}>
              <Label validado={entidadeValidacao.item_id}>
                {campoLabel('* Produto', 'item_id')}
              </Label>
              <Select
                onBlur={() => campoExit('item_id')}
                validado={entidadeValidacao.item_id}
                name="item_id"
                height={44}
                clear={false}
                maxMenuHeight={150}
                placeholder="Digite o produto"
                onChange={digitarSelect}
                defaultValue={entidade.item_id}
                options={produtos}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={25}>
              <Label validado={entidadeValidacao.quantidade}>
                {campoLabel('* Quantidade', 'quantidade')}
              </Label>
              <Input
                maxLength={12}
                onBlur={() => campoExit('quantidade')}
                validado={entidadeValidacao.quantidade}
                name="quantidade"
                value={entidade.quantidade}
                onChange={digitarInput}
                placeholder="Digite a quantidade"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={25}>
              <Label validado={entidadeValidacao.valorunitario}>
                {campoLabel('* Valor unitário', 'valorunitario')}
              </Label>
              <Input
                maxLength={12}
                onBlur={() => campoExit('valorunitario')}
                validado={entidadeValidacao.valorunitario}
                name="valorunitario"
                min={0}
                value={entidade.valorunitario}
                onChange={digitarInput}
                placeholder="Digite o valor unitário"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={25}>
              <Label>Valor desconto</Label>
              <Input
                maxLength={12}
                name="valordesconto"
                value={entidade.valordesconto}
                onChange={digitarInput}
                placeholder="Digite o desconto"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={25}>
              <Label>TOTAL</Label>
              <Input
                disabled={true}
                name="valortotal"
                value={`R$ ${parseFloat(entidade.valortotal)
                  .toFixed(2)
                  .replace('.', ',')}`}
                placeholder="TOTAL"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>

          <div>
            <CorpoFormButton type="submit">
              <div>
                <FaSave size={18} color={'#fff'} />
              </div>
              Salvar
            </CorpoFormButton>
            <CorpoFormButton tipo="cancel" onClick={cancelar}>
              <div>
                <FaTimes size={18} color={'#029f74'} />
              </div>
              Cancelar
            </CorpoFormButton>
          </div>
        </CorpoForm>
      </Corpo>
    </Container>
  );
}
