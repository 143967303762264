import api from '../services/api';

class Permissions {
  async visualizaGeral() {
    const visualizageral = await localStorage
      .getItem('@3reasy:USER_VG')
      .substring(3, 4);
    if (visualizageral === '0') {
      return this.colaborador();
    } else {
      return null;
    }
  }
  async visualizaCusto() {
    const visualizageral = await localStorage
      .getItem('@3reasy:USER_VC');
    return visualizageral ;
  }
  async colaborador() {
    const idusuario = await localStorage.getItem('@3reasy:USER_ID');
    const r = await api.get('/colaborador', { params: { idusuario } });
    return r.data[0].id;
  }
  async colaboradorNome() {
    const idusuario = await localStorage.getItem('@3reasy:USER_ID');
    const r = await api.get('/colaborador', { params: { idusuario } });
    return r.data[0].nome;
  }
}

export default new Permissions();
