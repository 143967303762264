import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../../components/Header';
import Menu from '../../../components/Menu';

import { Wrapper, Area } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Menu />
      <Area>
        <Header />
        {children}
      </Area>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
