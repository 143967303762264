class Values {
  async numeroBanco(valor) {
    let resultado = String(valor);
    resultado = await resultado.replace(',', '.');
    resultado = await resultado.replace('R$', '');
    return resultado;
  }
}

export default new Values();
