class Orders {
  async ordenarStr(objeto, campo) {
    const res =
      (await campo.desc) === false
        ? objeto.sort(function (a, b) {
            console.log(typeof a[campo.campo]);
            if (typeof a[campo.campo] !== 'undefined') {
              return a[campo.campo].toLowerCase() < b[campo.campo].toLowerCase()
                ? -1
                : a[campo.campo].toLowerCase() > b[campo.campo].toLowerCase()
                ? 1
                : 0;
            }
            const split = campo.campo.split('.');
            return a[split[0]][split[1]].toLowerCase() <
              b[split[0]][split[1]].toLowerCase()
              ? -1
              : a[split[0]][split[1]].toLowerCase() >
                b[split[0]][split[1]].toLowerCase()
              ? 1
              : 0;
          })
        : objeto.sort(function (a, b) {
            if (typeof a[campo.campo] !== 'undefined') {
              return a[campo.campo].toLowerCase() > b[campo.campo].toLowerCase()
                ? -1
                : a[campo.campo].toLowerCase() < b[campo.campo].toLowerCase()
                ? 1
                : 0;
            }
            const split = campo.campo.split('.');
            return a[split[0]][split[1]].toLowerCase() >
              b[split[0]][split[1]].toLowerCase()
              ? -1
              : a[split[0]][split[1]].toLowerCase() <
                b[split[0]][split[1]].toLowerCase()
              ? 1
              : 0;
          });
    return res;
  }

  async ordenarNull(objeto, campo) {
    const res =
      (await campo.desc) === false
        ? objeto.sort(function (a, b) {
            return (
              (a[campo.campo] === null) - (b[campo.campo] === null) ||
              +(a[campo.campo] > b[campo.campo]) ||
              -(a[campo.campo] < b[campo.campo])
            );
          })
        : objeto.sort(function (a, b) {
            return (
              (a[campo.campo] === null) - (b[campo.campo] === null) ||
              -(a[campo.campo] > b[campo.campo]) ||
              +(a[campo.campo] < b[campo.campo])
            );
          });
    return res;
  }

  async ordenarInt(objeto, campo) {
    console.log(objeto, campo);
    console.log(null > 'oi');
    const res =
      (await campo.desc) === false
        ? objeto.sort(function (a, b) {
            if (typeof a[campo.campo] !== 'undefined') {
              return a[campo.campo] < b[campo.campo]
                ? -1
                : a[campo.campo] > b[campo.campo]
                ? 1
                : 0;
            }
            const split = campo.campo.split('.');
            return a[split[0]][split[1]] < b[split[0]][split[1]]
              ? -1
              : a[split[0]][split[1]] > b[split[0]][split[1]]
              ? 1
              : 0;
          })
        : objeto.sort(function (a, b) {
            if (typeof a[campo.campo] !== 'undefined') {
              return a[campo.campo] > b[campo.campo]
                ? -1
                : a[campo.campo] < b[campo.campo]
                ? 1
                : 0;
            }
            const split = campo.campo.split('.');
            return a[split[0]][split[1]] > b[split[0]][split[1]]
              ? -1
              : a[split[0]][split[1]] < b[split[0]][split[1]]
              ? 1
              : 0;
          });
    return res;
  }

  async ordenarNumeric(objeto, campo) {
    const res =
      (await campo.desc) === false
        ? objeto.sort(function (a, b) {
            if (typeof a[campo.campo] !== 'undefined') {
              return a[campo.campo] - b[campo.campo];
            }
            const split = campo.campo.split('.');
            return a[split[0]][split[1]] - b[split[0]][split[1]];
          })
        : objeto.sort(function (a, b) {
            if (typeof a[campo.campo] !== 'undefined') {
              return b[campo.campo] - a[campo.campo];
            }
            const split = campo.campo.split('.');
            return b[split[0]][split[1]] - a[split[0]][split[1]];
          });
    return res;
  }
}

export default new Orders();
