import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #eaeaea;
`;

export const Colunas = styled.div`
  height: 100%;
  width: 50%;
  display: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  margin: ${props => (props.left ? '0px 0px 0px 10px' : '0px 10px 0px 10px')};
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling-y: touch;
`;

export const Grafico = styled.div`
  width: 100%;
  height: ${props => (props.size !== null ? props.size : '50%')};
  padding: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  background: #fff;
  margin: 0px 0px 8px 0px;
`;

export const GraficoL1 = styled.div`
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GraficoTitulo = styled.div`
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #585858;
  > strong {
    margin-right: 10px;
  }
  > div {
    width: 120px;
    margin-right: 7px;
    height: 44px;
  }
`;

export const GraficoConsulta = styled.div`
  width: 63px;
  height: 44px;
`;

export const GraficoConsultaBotao = styled.button`
  background: #029f74;
  border-radius: 5px;
  width: 55px;
  height: 44px;
  border: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  margin-right: 8px;
  font-weight: bold;
  div {
    margin-right: 7px;
  }
  &:hover {
    background: ${darken(0.05, '#029f74')};
  }
`;

export const GraficoCorpo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 7px 10px 10px 0px;
  align-items: center;
  justify-content: center;
`;

export const GraficoCard = styled.div`
  width: 33%;
  height: 100px;
  margin-right: 5px;
  background: #f1f1f1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  strong {
    padding: 8px;
    height: 20px;
    color: #acabab;
  }
  p {
    color: #585858;
    height: 80px;
    padding-top: 30px;
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
  }
`;
