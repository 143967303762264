//imports

class Relatorio {
  async relatorio(titulo, cabecalho, conteudo, total = 0) {
    var style = '<style>';
    style = style + 'table {width: 100%; font: 14px Roboto, sans-serif;}';
    style = style + 'p {font: 14px Roboto, sans-serif; font-weight: bold}';
    style =
      style +
      'table, th, td {border: solid 1px #DDD; border-collapse: collapse;';
    style = style + 'padding: 2px 3px; text-align: center;}';
    style = style + '</style>';

    var win = window.open('', '');
    win.document.write('<html><head>');
    win.document.write(`<title>${titulo}</title>`);
    win.document.write(style);
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write('<table>');
    win.document.write('<thead>');
    win.document.write(`${cabecalho}`);
    win.document.write('</thead>');
    win.document.write(`<tr>${conteudo}</tr>`);
    if (total > 0) win.document.write(`<p>TOTAL R$ ${total}</p>`);
    win.document.write('</body></html>');
    win.document.close();
    win.print();
  }
}

export default new Relatorio();
