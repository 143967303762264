import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Login from '../pages/Login';
import Registro from '../pages/Registro';
import Dashboard from '../pages/Dashboard';
import Pagina404 from '../pages/Pagina404';

import Cidade from '../pages/Cidade/Listagem';
import CidadeCadastro from '../pages/Cidade/Cadastro';
import Cliente from '../pages/Cliente/Listagem';
import ClienteCadastro from '../pages/Cliente/Cadastro';
import Produto from '../pages/Produto/Listagem';

import ContaReceber from '../pages/ContaReceber/Listagem';
import ContaPagar from '../pages/ContaPagar/Listagem';
import Venda from '../pages/Venda/Listagem';

import ProdutoCadastro from '../pages/Produto/Cadastro';
import Proposta from '../pages/Proposta/Listagem';
import PropostaCadastro from '../pages/Proposta/Cadastro';
import PropostaCadastroItem from '../pages/Proposta/Cadastro/Item/Cadastro';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/registro" exact component={Registro} />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/cidades" exact component={Cidade} isPrivate />
      <Route
        path="/cidades-cadastro"
        exact
        component={CidadeCadastro}
        isPrivate
      />
      <Route path="/clientes" exact component={Cliente} isPrivate />
      <Route
        path="/clientes-cadastro"
        exact
        component={ClienteCadastro}
        isPrivate
      />
      <Route path="/produtos" exact component={Produto} isPrivate />
      <Route
        path="/produtos-cadastro"
        exact
        component={ProdutoCadastro}
        isPrivate
      />

      <Route path="/contas-receber" exact component={ContaReceber} isPrivate />
      <Route path="/contas-pagar" exact component={ContaPagar} isPrivate />
      <Route path="/vendas" exact component={Venda} isPrivate />

      <Route path="/propostas" exact component={Proposta} isPrivate />
      <Route
        path="/propostas-cadastro"
        exact
        component={PropostaCadastro}
        isPrivate
      />
      <Route
        path="/propostas-cadastro-produtos"
        exact
        component={PropostaCadastroItem}
        isPrivate
      />

      <Route path="/" component={Pagina404} isPrivate />
    </Switch>
  );
}
