import styled from 'styled-components';
import { darken } from 'polished';

export const Title = styled.h1`
  color: ${darken(0.05, '#017c5f')};
  font-size: 18px;
`;

export const Back = styled.button`
  margin-top: 10px;
  color: ${darken(0.05, '#017c5f')};
  font-size: 14px;
  background: transparent;
  width: 100%;
  border: 0;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  input {
    width: 315px;
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 1);
    }
  }

  span {
    color: #000;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 12px;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #017c5f;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#017c5f')};
    }
  }

  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`;
