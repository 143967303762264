import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  FaPrint,
  FaSearch,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';

import history from '../../../services/history';
import date from '../../../lib/dates';
import order from '../../../lib/orders';
import relatorio from '../../../lib/relatorios';
import api from '../../../services/api';
import {
  Container,
  Titulo,
  TituloBotao,
  Corpo,
  CorpoFiltro,
  CorpoTabela,
  TabelaTd,
  TabelaTh,
  CorpoFiltroInput,
  CorpoFiltroBotao,
  CorpoPaginacao,
  TabelaTdIcone,
} from '../../../styles/listagem';

export default function ContaReceber() {
  // States
  const [entidades, setEntidades] = useState([]);
  const [paginas, setPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [registros, setRegistros] = useState(1);
  const [ordem, setOrdem] = useState({ campo: 'vcto', desc: false });

  // Acha Entidade
  async function carregar(mes, ano) {
    // prepara datas
    const vctoinicial = `${date.dataSQL(
      date.primeiroDiaMes(mes, ano)
    )}T00:00:00.000Z`;
    const vctofinal = `${date.dataSQL(
      date.ultimoDiaMes(mes, ano)
    )}T23:59:59.000Z`;

    const response = await api.get('/ctarec', {
      params: {
        vctoinicial,
        vctofinal,
      },
    });
    await setOrdem({ campo: 'vcto', desc: false });
    await setEntidades(response.data);
    await setRegistros(response.data.length);
    await setPaginas(Math.ceil(response.data.length / 10));
    await setPaginaAtual(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
  }

  // Ordena a entidade
  async function ordenar(campo) {
    ordem.campo === campo
      ? setOrdem({ campo, desc: !ordem.desc })
      : setOrdem({ campo, desc: false });
    const o =
      ordem.campo === campo
        ? { campo, desc: !ordem.desc }
        : { campo, desc: false };
    const result =
      o.campo === 'pgto'
        ? await order.ordenarNull(entidades, o)
        : o.campo === 'id'
        ? await order.ordenarInt(entidades, o)
        : o.campo === 'valor'
        ? await order.ordenarNumeric(entidades, o)
        : await order.ordenarStr(entidades, o);
    setEntidades([]);
    setEntidades(result);
  }
  function ordenarCor(c, upDown) {
    return ordem.campo === c && ordem.desc === upDown ? '#585858' : '#dedcdc';
  }

  // Form show
  useEffect(() => {
    // aqui setar mes e ano
    let mes = [];
    let ano = [];
    let aux = [];
    const urn = window.location.href.split('?');
    if (urn[1] !== undefined) {
      aux = urn[1].split('&');
      mes = aux[0].split('=');
      ano = aux[1].split('=');
    }
    carregar(mes[1], ano[1]);
    // eslint-disable-next-line
  }, []);

  // Controle de paginacao
  function digitarPaginaMenos() {
    if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1);
  }
  function digitarPaginaMais() {
    if (paginaAtual !== paginas) setPaginaAtual(paginaAtual + 1);
  }
  function corIconePaginacaoIni() {
    if (paginaAtual <= 1) return '#f8f8f8';
    else return '#029f74';
  }
  function corIconePaginacaoFim() {
    if (paginas === paginaAtual) return '#f8f8f8';
    else return '#029f74';
  }
  function paginacao(p) {
    if (p === 1) return (paginaAtual - 1) * 10;
    else return paginaAtual * 10;
  }

  function imprimir() {
    const cabecalho =
      '<th>CLIENTE</th><th>VENCIMENTO</th><th>PAGAMENTO</th><th>VALOR</th>';
    let conteudo = '';
    entidades.map(
      e =>
        (conteudo =
          conteudo +
          `<tr key=${e.id}> ` +
          `<td>${e.cliente.razaosocial}</td>  ` +
          `<td>${e.vcto}</td> ` +
          `<td>${e.pgto === null ? '' : e.pgto}</td> ` +
          `<td>${
            e.valor === null ? '' : `R$ ${e.valor.replace('.', ',')}`
          }</td></tr>`)
    );
    relatorio.relatorio('RELAÇÃO DE CONTAS A RECEBER', cabecalho, conteudo);
  }

  // Render da tela
  return (
    <Container>
      <Titulo>
        <strong>LISTAGEM DE CONTAS A RECEBER</strong>
        <div>
          <TituloBotao onClick={imprimir}>
            <div>
              <FaPrint size={18} color={'#029f74'} />
            </div>
            Imprimir
          </TituloBotao>
        </div>
      </Titulo>
      <Corpo>
        <CorpoTabela id="tabela">
          <table>
            <thead>
              <tr>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('cliente.razaosocial');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('cliente.razaosocial', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('cliente.razaosocial', true)}
                    />
                  </button>
                  Cliente
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('vcto');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('vcto', false)} />
                    <FaArrowDown size={14} color={ordenarCor('vcto', true)} />
                  </button>
                  Vencimento
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('pgto');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('pgto', false)} />
                    <FaArrowDown size={14} color={ordenarCor('pgto', true)} />
                  </button>
                  Pagamento
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('valor');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('valor', false)} />
                    <FaArrowDown size={14} color={ordenarCor('valor', true)} />
                  </button>
                  Valor
                </TabelaTh>
              </tr>
            </thead>
            <tbody>
              {entidades.slice(paginacao(1), paginacao(2)).map(e => (
                <tr key={e.id}>
                  <TabelaTd width={'40%'}>{e.cliente.razaosocial}</TabelaTd>
                  <TabelaTd width={'20%'}>{e.vcto}</TabelaTd>
                  <TabelaTd width={'20%'}>{e.pgto}</TabelaTd>
                  <TabelaTd width={'20%'}>{`R$ ${e.valor.replace(
                    '.',
                    ','
                  )}`}</TabelaTd>
                </tr>
              ))}
            </tbody>
          </table>
        </CorpoTabela>
        <CorpoPaginacao>
          <span>{registros} registro(s) encontrado(s)</span>
          <div>
            <button onClick={digitarPaginaMenos}>
              <FaChevronCircleLeft size={26} color={corIconePaginacaoIni()} />
            </button>
            <strong>
              {paginaAtual}/{paginas}
            </strong>
            <button onClick={digitarPaginaMais}>
              <FaChevronCircleRight size={26} color={corIconePaginacaoFim()} />
            </button>
          </div>
        </CorpoPaginacao>
      </Corpo>
    </Container>
  );
}
