import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  FaPrint,
  FaSearch,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';

import history from '../../../services/history';
import Select from '../../../components/Select';
import date from '../../../lib/dates';
import order from '../../../lib/orders';
import relatorio from '../../../lib/relatorios';
import api from '../../../services/api';
import {
  Container,
  Titulo,
  TituloBotao,
  Corpo,
  CorpoFiltro,
  CorpoTabela,
  TabelaTd,
  TabelaTh,
  CorpoPaginacao,
  CorpoFiltroSelect,
  TabelaTdIcone,
} from '../../../styles/listagem';

export default function Venda() {
  // States
  const [entidadeAtiva, setEntidadeAtiva] = useState(2);

  const [entidadeVenda, setEntidadeVenda] = useState([]);
  const [paginasVenda, setPaginasVenda] = useState(1);
  const [paginaAtualVenda, setPaginaAtualVenda] = useState(1);
  const [registrosVenda, setRegistrosVenda] = useState(1);
  const [ordemVenda, setOrdemVenda] = useState({ campo: 'data', desc: false });

  const [entidadeNota, setEntidadeNota] = useState([]);
  const [paginasNota, setPaginasNota] = useState(1);
  const [paginaAtualNota, setPaginaAtualNota] = useState(1);
  const [registrosNota, setRegistrosNota] = useState(1);
  const [ordemNota, setOrdemNota] = useState({ campo: 'data', desc: false });

  const [selectEntidade] = useState([
    {
      id: 1,
      title: 'Vendas',
    },
    {
      id: 2,
      title: 'Notas',
    },
  ]);

  // Acha Entidade
  async function carregar(mes, ano) {
    // prepara datas
    const datainicial = `${date.dataSQL(
      date.primeiroDiaMes(mes, ano)
    )}T00:00:00.000Z`;
    const datafinal = `${date.dataSQL(
      date.ultimoDiaMes(mes, ano)
    )}T23:59:59.000Z`;

    const response = await api.get('/venda', {
      params: {
        datainicial,
        datafinal,
      },
    });
    await setOrdemVenda({ campo: 'data', desc: false });
    await setEntidadeVenda(response.data);
    await setRegistrosVenda(response.data.length);
    await setPaginasVenda(Math.ceil(response.data.length / 10));
    await setPaginaAtualVenda(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);

    const responseNota = await api.get('/nota', {
      params: {
        datainicial,
        datafinal,
        entradasaida: 1,
      },
    });
    await setOrdemNota({ campo: 'data', desc: false });
    await setEntidadeNota(responseNota.data);
    await setRegistrosNota(responseNota.data.length);
    await setPaginasNota(Math.ceil(responseNota.data.length / 10));
    await setPaginaAtualNota(
      Math.ceil(responseNota.data.length / 10) > 0 ? 1 : 0
    );
  }

  // Ordena a entidade
  async function ordenar(campo) {
    if (entidadeAtiva === 1) {
      ordemVenda.campo === campo
        ? setOrdemVenda({ campo, desc: !ordemVenda.desc })
        : setOrdemVenda({ campo, desc: false });
      const o =
        ordemVenda.campo === campo
          ? { campo, desc: !ordemVenda.desc }
          : { campo, desc: false };
      const result =
        o.campo === 'data' || o.campo === 'cliente.razaosocial'
          ? await order.ordenarNull(entidadeVenda, o)
          : o.campo === 'id'
          ? await order.ordenarInt(entidadeVenda, o)
          : o.campo === 'valortotal'
          ? await order.ordenarNumeric(entidadeVenda, o)
          : await order.ordenarStr(entidadeVenda, o);
      setEntidadeVenda([]);
      setEntidadeVenda(result);
    } else {
      ordemNota.campo === campo
        ? setOrdemNota({ campo, desc: !ordemNota.desc })
        : setOrdemNota({ campo, desc: false });
      const o =
        ordemNota.campo === campo
          ? { campo, desc: !ordemNota.desc }
          : { campo, desc: false };
      const result =
        o.campo === 'data' || o.campo === 'cliente.razaosocial'
          ? await order.ordenarNull(entidadeNota, o)
          : o.campo === 'id'
          ? await order.ordenarInt(entidadeNota, o)
          : o.campo === 'valortotal'
          ? await order.ordenarNumeric(entidadeNota, o)
          : await order.ordenarStr(entidadeNota, o);
      setEntidadeNota([]);
      setEntidadeNota(result);
    }
  }
  function ordenarCor(c, upDown) {
    if (entidadeVenda) {
      return ordemVenda.campo === c && ordemVenda.desc === upDown
        ? '#585858'
        : '#dedcdc';
    } else {
      return ordemNota.campo === c && ordemNota.desc === upDown
        ? '#585858'
        : '#dedcdc';
    }
  }

  // Form show
  useEffect(() => {
    // aqui setar mes e ano
    let mes = [];
    let ano = [];
    let aux = [];
    const urn = window.location.href.split('?');
    if (urn[1] !== undefined) {
      aux = urn[1].split('&');
      mes = aux[0].split('=');
      ano = aux[1].split('=');
    }
    carregar(mes[1], ano[1]);
    // eslint-disable-next-line
  }, []);

  // Controle de paginacao
  function digitarPaginaMenos() {
    if (entidadeAtiva === 1) {
      if (paginaAtualVenda > 1) setPaginaAtualVenda(paginaAtualVenda - 1);
    } else {
      if (paginaAtualNota > 1) setPaginaAtualNota(paginaAtualNota - 1);
    }
  }
  function digitarPaginaMais() {
    if (entidadeAtiva === 1) {
      if (paginaAtualVenda !== paginasVenda)
        setPaginaAtualVenda(paginaAtualVenda + 1);
    } else {
      if (paginaAtualNota !== paginasNota)
        setPaginaAtualNota(paginaAtualNota + 1);
    }
  }
  function corIconePaginacaoIni() {
    if (entidadeAtiva === 1) {
      if (paginaAtualVenda <= 1) return '#f8f8f8';
      else return '#029f74';
    } else {
      if (paginaAtualNota <= 1) return '#f8f8f8';
      else return '#029f74';
    }
  }
  function corIconePaginacaoFim() {
    if (entidadeAtiva === 1) {
      if (paginasVenda === paginaAtualVenda) return '#f8f8f8';
      else return '#029f74';
    } else {
      if (paginasNota === paginaAtualNota) return '#f8f8f8';
      else return '#029f74';
    }
  }
  function paginacao(p) {
    if (entidadeAtiva === 1) {
      if (p === 1) return (paginaAtualVenda - 1) * 10;
      else return paginaAtualVenda * 10;
    } else {
      if (p === 1) return (paginaAtualNota - 1) * 10;
      else return paginaAtualNota * 10;
    }
  }

  function imprimir() {
    if (entidadeAtiva === 1) {
      const cabecalho =
        '<th>ID</th><th>DATA</th><th>CLIENTE</th><th>VALOR</th>';
      let conteudo = '';
      entidadeVenda.map(
        e =>
          (conteudo =
            conteudo +
            `<tr key=${e.id}> ` +
            `<td>${e.id}</td>  ` +
            `<td>${e.data}</td> ` +
            `<td>${e.cliente !== null ? e.cliente.razaosocial : ''}</td> ` +
            `<td>${
              e.valortotal === null
                ? ''
                : `R$ ${e.valortotal.replace('.', ',')}`
            }</td></tr>`)
      );
      relatorio.relatorio('RELAÇÃO DE VENDAS', cabecalho, conteudo);
    } else {
      const cabecalho =
        '<th>ID</th><th>DATA</th><th>CLIENTE</th><th>VALOR</th>';
      let conteudo = '';
      entidadeNota.map(
        e =>
          (conteudo =
            conteudo +
            `<tr key=${e.id}> ` +
            `<td>${e.id}</td>  ` +
            `<td>${e.data}</td> ` +
            `<td>${
              e.cliente !== undefined ? e.cliente.razaosocial : ''
            }</td> ` +
            `<td>${
              e.valortotal === null
                ? ''
                : `R$ ${e.valortotal.replace('.', ',')}`
            }</td></tr>`)
      );
      relatorio.relatorio('RELAÇÃO DE VENDAS', cabecalho, conteudo);
    }
  }

  // Render da tela
  return (
    <Container>
      <Titulo>
        <strong>{`LISTAGEM DE ${
          entidadeAtiva === 1 ? 'VENDAS' : 'NOTAS'
        }`}</strong>
        <div>
          <TituloBotao onClick={imprimir}>
            <div>
              <FaPrint size={18} color={'#029f74'} />
            </div>
            Imprimir
          </TituloBotao>
        </div>
      </Titulo>
      <Corpo>
        <CorpoFiltro>
          <CorpoFiltroSelect>
            <Select
              name="filtroTipoData"
              height={40}
              clear={false}
              maxMenuHeight={150}
              placeholder="Digite o tipo de data"
              onChange={e => setEntidadeAtiva(e.id)}
              defaultValue={entidadeAtiva}
              options={selectEntidade}
            />
          </CorpoFiltroSelect>
        </CorpoFiltro>
        {entidadeAtiva === 1 ? (
          <CorpoTabela id="tabela">
            <table>
              <thead>
                <tr>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('id');
                      }}
                    >
                      <FaArrowUp size={14} color={ordenarCor('id', false)} />
                      <FaArrowDown size={14} color={ordenarCor('id', true)} />
                    </button>
                    ID.
                  </TabelaTh>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('data');
                      }}
                    >
                      <FaArrowUp size={14} color={ordenarCor('data', false)} />
                      <FaArrowDown size={14} color={ordenarCor('data', true)} />
                    </button>
                    Data
                  </TabelaTh>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('cliente.razaosocial');
                      }}
                    >
                      <FaArrowUp
                        size={14}
                        color={ordenarCor('cliente.razaosocial', false)}
                      />
                      <FaArrowDown
                        size={14}
                        color={ordenarCor('cliente.razaosocial', true)}
                      />
                    </button>
                    Cliente
                  </TabelaTh>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('valortotal');
                      }}
                    >
                      <FaArrowUp
                        size={14}
                        color={ordenarCor('valortotal', false)}
                      />
                      <FaArrowDown
                        size={14}
                        color={ordenarCor('valortotal', true)}
                      />
                    </button>
                    Valor Total
                  </TabelaTh>
                </tr>
              </thead>
              <tbody>
                {entidadeVenda.slice(paginacao(1), paginacao(2)).map(e => (
                  <tr key={e.id}>
                    <TabelaTd width={'20%'}>{e.id}</TabelaTd>
                    <TabelaTd width={'20%'}>{e.data}</TabelaTd>
                    <TabelaTd width={'40%'}>
                      {e.cliente !== null ? e.cliente.razaosocial : ''}
                    </TabelaTd>
                    <TabelaTd width={'20%'}>{`R$ ${e.valortotal.replace(
                      '.',
                      ','
                    )}`}</TabelaTd>
                  </tr>
                ))}
              </tbody>
            </table>
          </CorpoTabela>
        ) : (
          <CorpoTabela id="tabela">
            <table>
              <thead>
                <tr>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('id');
                      }}
                    >
                      <FaArrowUp size={14} color={ordenarCor('id', false)} />
                      <FaArrowDown size={14} color={ordenarCor('id', true)} />
                    </button>
                    ID.
                  </TabelaTh>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('data');
                      }}
                    >
                      <FaArrowUp size={14} color={ordenarCor('data', false)} />
                      <FaArrowDown size={14} color={ordenarCor('data', true)} />
                    </button>
                    Data
                  </TabelaTh>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('cliente.razaosocial');
                      }}
                    >
                      <FaArrowUp
                        size={14}
                        color={ordenarCor('cliente.razaosocial', false)}
                      />
                      <FaArrowDown
                        size={14}
                        color={ordenarCor('cliente.razaosocial', true)}
                      />
                    </button>
                    Cliente
                  </TabelaTh>
                  <TabelaTh>
                    <button
                      onClick={() => {
                        ordenar('valortotal');
                      }}
                    >
                      <FaArrowUp
                        size={14}
                        color={ordenarCor('valortotal', false)}
                      />
                      <FaArrowDown
                        size={14}
                        color={ordenarCor('valortotal', true)}
                      />
                    </button>
                    Valor Total
                  </TabelaTh>
                </tr>
              </thead>
              <tbody>
                {entidadeNota.slice(paginacao(1), paginacao(2)).map(e => (
                  <tr key={e.id}>
                    <TabelaTd width={'20%'}>{e.id}</TabelaTd>
                    <TabelaTd width={'20%'}>{e.data}</TabelaTd>
                    <TabelaTd width={'40%'}>
                      {e.cliente !== undefined ? e.cliente.razaosocial : ''}
                    </TabelaTd>
                    <TabelaTd width={'20%'}>{`R$ ${e.valortotal.replace(
                      '.',
                      ','
                    )}`}</TabelaTd>
                  </tr>
                ))}
              </tbody>
            </table>
          </CorpoTabela>
        )}
        <CorpoPaginacao>
          <span>
            {entidadeVenda === 1 ? registrosVenda : registrosNota} registro(s)
            encontrado(s)
          </span>
          <div>
            <button onClick={digitarPaginaMenos}>
              <FaChevronCircleLeft size={26} color={corIconePaginacaoIni()} />
            </button>
            <strong>
              {entidadeVenda === 1 ? paginaAtualVenda : paginaAtualNota}/
              {entidadeVenda === 1 ? paginasVenda : paginasNota}
            </strong>
            <button onClick={digitarPaginaMais}>
              <FaChevronCircleRight size={26} color={corIconePaginacaoFim()} />
            </button>
          </div>
        </CorpoPaginacao>
      </Corpo>
    </Container>
  );
}
