import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  /*width: 100%;*/
  height: 30%;
  align-items: center;
  justify-content: center;
`;

export const HeaderImg = styled.img`
  padding-left: 30%;
  padding-right: 30%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: flex-start;
`;

export const BodyDescribe = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  /*color: ${darken(0.05, '#017c5f')};*/
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 80px;
`;

export const Button = styled.a`
  background: ${props =>
    props.disabled ? '#017c5f' : darken(0.05, '#017c5f')};
  height: 70px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const ButtonText = styled.div`
  flex-direction: column;
  align-items: center;
  p {
    width: 100%;
    text-align: left;
    color: #fff;
    margin-bottom: 3px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
  }
`;

export const Tag = styled.div`
  margin-left: 6px;
  width: 60px;
  height: 15px;
  background: ${darken(0.05, '#017c5f')};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 11px;
`;

export const Cadastrar = styled.button`
  margin-top: 20px;
  border: 0;
  background: transparent;
  color: ${darken(0.05, '#017c5f')};
  /*font-weight: bold;*/
  font-size: 14px;
`;
