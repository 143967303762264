import styled from 'styled-components';
import { darken } from 'polished';

export const Header = styled.div`
  background: #029f74;
  position: sticky;
  min-height: 65px;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const Options = styled.div`
  height: 50px;
  width: 300px;
  display: flex;
  margin-right: 30px;
  justify-content: flex-end;
  align-items: center;
  button {
    border: 0;
    background: transparent;
    margin-left: 20px;
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    &:hover {
      background: ${darken(0.05, '#029f74')};
    }
  }
`;
