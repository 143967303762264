import styled from 'styled-components';
import { darken } from 'polished';

import DatePicker from 'react-datepicker';

/* ************* CONTAINER ************* */

export const Container = styled.div`
  display: nowrap;
  flex-direction: column;
  width: 100%;
  background: #eaeaea;
  overflow-y: scroll;
  -webkit-overflow-scrolling-y: touch;
`;

/* ************* TITULO ************* */

export const Titulo = styled.div`
  max-width: 95%;
  margin: 20px auto 5px auto;
  border-radius: 9px;
  width: 100%;
  height: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  strong {
    font-size: 16px;
    font-weight: bold;
    color: #585858;
  }
  div {
    display: flex;
    flex-direction: row;
  }
`;

/* ************* CORPO ************* */

export const Corpo = styled.div`
  max-width: 95%;
  margin: 0 auto 20px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fbfbfb;
  border-radius: 5px;
`;

export const CorpoFooter = styled.div`
  width: 100%;
  height: 15px;
`;

export const CorpoForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 30px 70px 30px 70px;
  width: 100%;
  > div {
    display: flex;
    flex-direction: row;
  }
`;

export const CorpoFormButton = styled.button`
  height: 40px;
  min-height: 40px;
  width: 150px;
  background: ${props => (props.tipo === 'cancel' ? '#FFF' : '#029f74')};
  font-weight: bold;
  color: ${props => (props.tipo === 'cancel' ? '#029f74' : '#FFF')};
  border: ${props => (props.tipo === 'cancel' ? '1px solid #e6e6e6' : '0')};
  border-radius: 5px;
  margin-top: 8px;
  margin-left: ${props => (props.tipo === 'cancel' ? '10px' : '3px')};
  margin-bottom: 18px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  div {
    margin-top: 2px;
    margin-right: 7px;
  }
  transition: background 0.2s;
  &:hover {
    background: ${props =>
      props.tipo === 'cancel'
        ? `${darken(0.05, '#FFF')}`
        : `${darken(0.05, '#029f74')}`};
  }
`;

export const CorpoTabela = styled.div`
  margin-left: 5px;
  margin-bottom: 7px;
  width: 98.9%;
  max-height: 545px;
  background: #fff;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #dedcdc;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        th {
          color: #585858;
          border-right: 1px solid #dedcdc;
          border-bottom: 1px solid #dedcdc;
          text-align: left;
          padding-left: 8px;
          height: 45px;
          background: #f1f1f1;
        }
      }
    }
    tbody {
      tr {
        height: 45px;
        width: 100%;
        &:nth-child(2n + 2) {
          background: #f8f8f8;
        }
        &:hover {
          background: ${darken(0.04, '#f3f3f3')};
        }
      }
    }
  }
`;

export const CorpoTabelaTd = styled.td`
  border-right: 1px solid #f1f1f1;
  padding-left: 8px;
  text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const CorpoTabelaAdicionar = styled.button`
  width: 120px;
  height: 35px;
  background: ${props => (props.block ? '#eaeaea' : '#029f74')};
  border: 0;
  border-radius: 5px;
  display: flex;
  margin-bottom: 5px;
  margin-left: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.block ? 'not-allowed' : 'pointer')};
  p {
    margin-left: 5px;
    color: #fff;
  }
  &:hover {
    background: ${props => (props.block ? null : darken(0.04, '#029f74'))};
  }
`;

export const CorpoTabelaIcone = styled.button`
  margin-right: 5px;
  border: 0;
  background: #f1f1f1;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  padding-top: 3px;
`;

export const CorpoLinha = styled.div`
  width: ${props => (props.border ? '98.8%' : '100%')};
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => (props.border ? '20px' : '5px')};
  border-bottom: ${props => (props.border ? '2px solid #eaeaea' : null)};
  margin-left: ${props => (props.border ? '5px' : '0')};
  > strong {
    color: #029f74;
    font-weight: bold;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const CorpoLinhaCampo = styled.div`
  width: ${props => (props.size ? `${props.size}%` : '100%')};
  height: 100%;
  padding: 3px 7px 3px 5px;
  > span {
    color: red;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
  }
  > div {
    width: 100%;
  }
`;

export const Input = styled.input`
  border-color: ${props =>
    props.validado === false
      ? 'red'
      : props.aviso === false
      ? '#FFBF00'
      : '#e6e6e6'};
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 44px;
  padding: ${props => (props.importar === true ? '12px 15px' : '0 15px')};
  margin-bottom: 4px;
  width: 100%;
  &:focus {
    border-color: #585858;
  }
`;

export const InputMulti = styled.textarea`
  border-color: ${props => (props.validado === false ? 'red' : '#e6e6e6')};
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 120px;
  resize: none;
  padding: 12px 15px;
  margin-bottom: 4px;
  width: 100%;
  max-width: 100%;
  &:focus {
    border-color: #585858;
  }
`;

export const DateP = styled(DatePicker)`
  border-color: ${props => (props.validado === false ? 'red' : '#e6e6e6')};
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 100% !important;
  height: 44px;
  padding: 0 15px;
`;

export const Label = styled.p`
  color: ${props =>
    props.validado === false
      ? 'red'
      : props.aviso === false
      ? '#FFBF00'
      : '#585858'};
  margin-left: 2px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
  height: 14px;
`;
