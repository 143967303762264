import React, { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Select from '../../../components/Select';
import emails from '../../../lib/emails';
import masks from '../../../lib/masks';
import selects from '../../../lib/selects';
import forms from '../../../lib/forms';
import dates from '../../../lib/dates';
import api from '../../../services/api';
import permissions from '../../../lib/permissions';
import {
  Container,
  Titulo,
  Corpo,
  CorpoForm,
  CorpoFormButton,
  CorpoLinha,
  CorpoLinhaCampo,
  CorpoFooter,
  Input,
  InputMulti,
  Label,
  DateP,
} from '../../../styles/cadastro';
import { Confirmation } from '../../../styles/confirmation';
import history from '../../../services/history';

export default function ClienteCadastro() {
  // States
  const [id, setId] = useState();
  const [fisicajuridica, setFisicaJuridica] = useState('FISICA');
  const [fjCaracteres, setFJCaracteres] = useState(14);

  const [fjPlaceholder, setFJPlaceholder] = useState('Digite o CPF');
  const [rgiePlaceholder, setRGIEPlaceholder] = useState('Digite o R.G.');
  const [razsocialPlaceholder, setRazSocialPlaceholder] = useState(
    'Digite o nome'
  );
  const [fantasiaPlaceholder, setFantasiaPlaceholder] = useState(
    'Digite o apelido'
  );

  const [razsocialLabel, setRazSocialLabel] = useState('Nome');
  const [fantasiaLabel, setFantasiaLabel] = useState('Apelido');
  const [cpfCnpjLabel, setCPFCNPJLabel] = useState('CPF');
  const [rgieLabel, setRGIELabel] = useState('RG');

  const [entidade, setEntidade] = useState({
    datainativo: null,
    datanascimento: null,
    razaosocial: null,
    fantasia: null,
    fisicajuridica: null,
    cpfcnpj: null,
    rginscricaoe: null,
    logradouro: null,
    numero: null,
    bairro: null,
    complemento: null,
    cidade_id: null,
    telefone1: null,
    telefone2: null,
    telefone3: null,
    email1: null,
    email2: null,
    email3: null,
    obs: null,
    colaborador_id: null,
  });
  const [entidadeValidacao, setEntidadeValidacao] = useState({
    razaosocial: true,
  });
  const [entidadeAviso, setEntidadeAviso] = useState({
    email1: true,
    email2: true,
    email3: true,
  });
  const [tipopessoas, setTipoPessoas] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradorHabilitado, setColaboradorHabilitado] = useState(true);
  const [gra, setGra] = useState(true);

  // Form Show
  useEffect(() => {
    // Pega o ID quando edicao
    let i = [];
    const urn = window.location.href.split('?');
    if (urn[1] !== undefined) {
      i = urn[1].split('=');
      setId(i[1]);
    }
    // Faz a consulta dos campos de select
    popularSelects();
    // Faz a consulta se visualiza geral ou nao
    async function habilita() {
      setColaboradorHabilitado(
        (await permissions.visualizaGeral()) === null ? false : true
      );
    }
    habilita();
    // Consulta dados do ID quando edicao
    if (i[1] !== undefined) {
      async function popularEntidade() {
        let c = await api.get(`cliente/${i[1]}`);
        // Faz ajustes de datas e valores decimais
        c.data.datanascimento !== null
          ? (c.data.datanascimento = await parseISO(
              `${dates.dataSQL(c.data.datanascimento)}T00:00:00`
            ))
          : (c.data.datanascimento = null);
        c.data.datainativo !== null
          ? (c.data.datainativo = await parseISO(
              `${dates.dataSQL(c.data.datainativo)}T00:00:00`
            ))
          : (c.data.datainativo = null);
        setEntidade(c.data);
        if (String(c.data.fisicajuridica) === 'FISICA') {
          setFisicaJuridica('FISICA');
        } else {
          setFisicaJuridica('JURIDICA');
        }
      }
      popularEntidade();
    } else {
      async function populaColaborador() {
        // Inclusao
        setEntidade(
          forms.populaFormulario(
            entidade,
            'colaborador_id',
            await permissions.colaborador()
          )
        );
      }
      populaColaborador();
    }
  }, []); // eslint-disable-next-line

  // Popula todos o campos selects
  async function popularSelects() {
    setTipoPessoas([
      { id: 'FISICA', title: 'PESSOA FÍSICA' },
      { id: 'JURIDICA', title: 'PESSOA JURÍDICA' },
    ]);
    const cd = await api.get('cidade');
    setCidades(
      await selects.ajustaColunas(cd.data, 'cidade', ' - ', 'uf', ' - ', 'cep')
    );
    const cb = await api.get('/select/colaborador');
    setColaboradores(await selects.ajustaColunasSelect(cb.data));
  }

  // Grava o cadastro e fecha formulario
  async function gravar(e) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();
    if (gra === true) {
      setGra(false);
      // Valida os campos obrigatorios
      const [o, v] = forms.validaFormulario(entidade, entidadeValidacao);
      setEntidadeValidacao(o);
      if (!v) {
        setGra(true);
        return false;
      }
      // Valida o cpf
      if (entidade.cpfcnpj !== null && entidade.cpfcnpj !== '') {
        const a = await api.get('/cliente', {
          params: {
            cpfcnpj: entidade.cpfcnpj,
          },
        });
        if (a.data.length === 1) {
          if (`${a.data[0].id}` !== id) {
            toast.error('CPF/CNPJ JA CADASTRADO EM OUTRO REGISTRO');
            setGra(true);
            return false;
          }
        }
      }
      // Valida os emails
      if (entidade.email1 !== null && entidade.email1 !== '') {
        if (!emails.isEmail(entidade.email1)) {
          toast.error('EMAIL 1 COM FORMATO INVÁLIDO');
          document.getElementById('email1').focus();
          setGra(true);
          return false;
        }
      }
      if (entidade.email2 !== null && entidade.email2 !== '') {
        if (!emails.isEmail(entidade.email2)) {
          toast.error('EMAIL 2 COM FORMATO INVÁLIDO');
          document.getElementById('email2').focus();
          setGra(true);
          return false;
        }
      }
      if (entidade.email3 !== null && entidade.email3 !== '') {
        if (!emails.isEmail(entidade.email3)) {
          toast.error('EMAIL 3 COM FORMATO INVÁLIDO');
          document.getElementById('email3').focus();
          setGra(true);
          return false;
        }
      }
      //
      let ent = entidade;
      if (isNaN(ent.datanascimento)) {
        ent.datanascimento = null;
      }
      if (isNaN(ent.datainativo)) {
        ent.datainativo = null;
      }
      ent.fisicajuridica = fisicajuridica;
      ent.importar = 1;
      // Limpa campos em branco
      ent = forms.preparaFormulario(ent);

      // Editar
      if (id !== undefined) {
        const response = await api.put(`cliente/${id}`, ent);
        if (response.status === 200) {
          toast.success('CLIENTE EDITADO COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO EDITAR O CLIENTE');
          setGra(true);
        }
      }
      // Incluir
      else {
        const response = await api.post('cliente', ent);
        if (response.status === 200) {
          toast.success('CLIENTE ADICIONADO COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO ADICIONAR O CLIENTE');
          setGra(true);
        }
      }
      history.push('clientes');
    }
  }

  // Cancela o cadastro e fecha o formulario
  async function cancelar(e) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Confirma o cancelamento do cadastro?</p>
            <div>
              <button
                onClick={async () => {
                  toast.warn('CADASTRO CANCELADO');
                  history.push('clientes');
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
  }

  // Digita o campo no objeto e popula com seu devido valor
  function digitarDate(e) {
    setEntidade(forms.populaFormulario(entidade, this.name, e));
  }
  function digitarSelect(e) {
    if (e === null) {
      setEntidade(forms.populaFormulario(entidade, this.name, null));
    } else {
      if (e.id !== undefined) {
        setEntidade(forms.populaFormulario(entidade, this.name, e.id));
      }
    }
  }
  function digitarInput(e) {
    let valor;
    // preencheu um INPUT
    switch (e.target.name) {
      case 'cpfcnpj':
        valor =
          fisicajuridica === 'FISICA'
            ? masks.cpf(e.target.value)
            : masks.cnpj(e.target.value);
        break;
      case 'telefone1':
        valor = masks.fone(e.target.value);
        break;
      case 'telefone2':
        valor = masks.fone(e.target.value);
        break;
      case 'telefone3':
        valor = masks.fone(e.target.value);
        break;
      default:
        valor = e.target.value;
    }
    setEntidade(forms.populaFormulario(entidade, e.target.name, valor));
  }
  function digitarFisicaJuridica(e) {
    setFisicaJuridica(e.id);
    if (e.id === 'FISICA') {
      setFJCaracteres(14);
      setFJPlaceholder('Digite o CPF');
      setRGIEPlaceholder('Digite o R.G.');
      setRazSocialPlaceholder('Digite o nome');
      setFantasiaPlaceholder('Digite o apelido');
      setRazSocialLabel('Nome');
      setFantasiaLabel('Apelido');
      setCPFCNPJLabel('CPF');
      setRGIELabel('RG');
    } else {
      setFJCaracteres(18);
      setFJPlaceholder('Digite o CNPJ');
      setRGIEPlaceholder('Digite a I.E.');
      setRazSocialPlaceholder('Digite a razão social');
      setFantasiaPlaceholder('Digite a fantasia');
      setRazSocialLabel('Razão social');
      setFantasiaLabel('Fantasia');
      setCPFCNPJLabel('CNPJ');
      setRGIELabel('Inscrição estadual');
    }
  }

  // Faz a validacao no exit do campo
  function campoExit(campo) {
    setEntidadeValidacao(
      forms.validaFormularioCampo(entidade, entidadeValidacao, campo)
    );
  }

  // Faz a validacao dos emails
  function validaEmails(objeto, objetoValidacao, campo) {
    let obj = {};
    for (var key in objetoValidacao) {
      if (key === campo) {
        if (objeto[key] !== null && objeto[key] !== '') {
          if (emails.isEmail(objeto[key]) === true) {
            obj[key] = true;
          } else {
            obj[key] = false;
          }
        } else {
          obj[key] = true;
        }
      } else {
        obj[key] = objetoValidacao[key];
      }
    }
    return obj;
  }
  function campoExitAviso(campo) {
    setEntidadeAviso(validaEmails(entidade, entidadeAviso, campo));
    /* let e = entidadeAviso;
    if (entidade[campo] !== null && entidade[campo] !== '') {
      emails.isEmail(entidade[campo]) ? (e[campo] = true) : (e[campo] = false);
    } else {
      e[campo] = true;
    } */
  }

  // Escreve a label conforme parametros de validacao
  function campoLabel(label, field) {
    return `
      ${label}
      ${!entidadeValidacao[field] ? ' (Campo obrigatório)' : ''}
      `;
  }

  // Renderiza o codigo em tela
  return (
    <Container>
      <Titulo>
        <strong>CADASTRO DE CLIENTES</strong>
      </Titulo>
      <Corpo>
        <CorpoForm autoComplete="off" onSubmit={gravar}>
          <CorpoLinha border={true}>
            <strong>DADOS PRINCIPAIS</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={25}>
              <Label>Pessoa física ou jurídica</Label>
              <Select
                name="fisicajuridica"
                height={44}
                clear={false}
                placeholder="Selecione o tipo de pessoa"
                onChange={digitarFisicaJuridica}
                defaultValue={fisicajuridica}
                options={tipopessoas}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label validado={entidadeValidacao.razaosocial}>
                {campoLabel(`* ${razsocialLabel}`, 'razaosocial')}
              </Label>
              <Input
                onBlur={() => campoExit('razaosocial')}
                validado={entidadeValidacao.razaosocial}
                name="razaosocial"
                value={entidade.razaosocial}
                onChange={digitarInput}
                placeholder={razsocialPlaceholder}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={50}>
              <Label>{fantasiaLabel}</Label>
              <Input
                name="fantasia"
                value={entidade.fantasia}
                onChange={digitarInput}
                placeholder={fantasiaPlaceholder}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label>{cpfCnpjLabel}</Label>
              <Input
                name="cpfcnpj"
                value={entidade.cpfcnpj}
                onChange={digitarInput}
                placeholder={fjPlaceholder}
                maxLength={fjCaracteres}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={50}>
              <Label>{rgieLabel}</Label>
              <Input
                maxLength={12}
                name="rginscricaoe"
                value={entidade.rginscricaoe}
                onChange={digitarInput}
                placeholder={rgiePlaceholder}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>ENDEREÇO</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={60}>
              <Label>Logradouro</Label>
              <Input
                name="logradouro"
                value={entidade.logradouro}
                onChange={digitarInput}
                placeholder="Digite o nome da rua, avenida, rodovia, etc"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={40}>
              <Label>Bairro</Label>
              <Input
                name="bairro"
                value={entidade.bairro}
                onChange={digitarInput}
                placeholder="Digite o nome do bairro ou localidade"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={60}>
              <Label>Cidade</Label>
              <Select
                name="cidade_id"
                height={44}
                clear={true}
                placeholder="Digite a cidade"
                onChange={digitarSelect}
                defaultValue={entidade.cidade_id}
                options={cidades}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={20}>
              <Label>Número</Label>
              <Input
                maxLength={10}
                name="numero"
                value={entidade.numero}
                onChange={digitarInput}
                placeholder="Digite o número"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={20}>
              <Label>Complemento</Label>
              <Input
                name="complemento"
                value={entidade.complemento}
                onChange={digitarInput}
                placeholder="Digite o complemento"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>CONTATOS</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={33.333}>
              <Label>Telefone 1</Label>
              <Input
                maxLength={15}
                name="telefone1"
                value={entidade.telefone1}
                onChange={digitarInput}
                placeholder="Digite o telefone 1"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label>Telefone 2</Label>
              <Input
                maxLength={15}
                name="telefone2"
                value={entidade.telefone2}
                onChange={digitarInput}
                placeholder="Digite o telefone 2"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label>Telefone 3</Label>
              <Input
                maxLength={15}
                name="telefone3"
                value={entidade.telefone3}
                onChange={digitarInput}
                placeholder="Digite o telefone 3"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={33.333}>
              <Label aviso={entidadeAviso.email1}>
                {entidadeAviso.email1 === true
                  ? 'E-mail 1'
                  : 'E-mail 1 (Formato inválido)'}
              </Label>
              <Input
                onBlur={() => campoExitAviso('email1')}
                aviso={entidadeAviso.email1}
                id="email1"
                name="email1"
                value={entidade.email1}
                onChange={digitarInput}
                placeholder="Digite o e-mail 1"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label aviso={entidadeAviso.email2}>
                {entidadeAviso.email2
                  ? 'E-mail 2'
                  : 'E-mail 2 (Formato inválido)'}
              </Label>
              <Input
                onBlur={() => campoExitAviso('email2')}
                aviso={entidadeAviso.email2}
                id="email2"
                name="email2"
                value={entidade.email2}
                onChange={digitarInput}
                placeholder="Digite o e-mail 2"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label aviso={entidadeAviso.email3}>
                {entidadeAviso.email3
                  ? 'E-mail 3'
                  : 'E-mail 3 (Formato inválido)'}
              </Label>
              <Input
                onBlur={() => campoExitAviso('email3')}
                aviso={entidadeAviso.email3}
                id="email3"
                name="email3"
                value={entidade.email3}
                onChange={digitarInput}
                placeholder="Digite o e-mail 3"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>OUTRAS INFOS.</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label>Vendedor</Label>
              <Select
                name="colaborador_id"
                height={44}
                clear={false}
                maxMenuHeight={150}
                disabled={colaboradorHabilitado}
                placeholder="Digite o vendedor"
                onChange={digitarSelect}
                defaultValue={entidade.colaborador_id}
                options={colaboradores}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={25}>
              <Label>Data de nascimento/fundação</Label>
              <DateP
                name="datanascimento"
                selected={entidade.datanascimento}
                onChange={digitarDate}
                locale={pt}
                dateFormat="dd/MM/yyyy"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={25}>
              <Label>Data de inativação</Label>
              <DateP
                name="datainativo"
                selected={entidade.datainativo}
                onChange={digitarDate}
                locale={pt}
                dateFormat="dd/MM/yyyy"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={100}>
              <Label>Observações</Label>
              <InputMulti
                importar={true}
                name="obs"
                value={entidade.obs}
                onChange={digitarInput}
                placeholder="Digite a observação"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <div>
            <CorpoFormButton type="submit">
              <div>
                <FaSave size={18} color={'#fff'} />
              </div>
              Salvar
            </CorpoFormButton>
            <CorpoFormButton tipo="cancel" onClick={cancelar}>
              <div>
                <FaTimes size={18} color={'#029f74'} />
              </div>
              Cancelar
            </CorpoFormButton>
          </div>
        </CorpoForm>
      </Corpo>
      <CorpoFooter />
    </Container>
  );
}
