class Selects {
  async ajustaColunas(
    array,
    campo,
    delimitador1 = '',
    campo2 = null,
    delimitador2 = '',
    campo3 = null,
    delimitador3 = '',
    campo4 = null
  ) {
    let resultado = [];
    array.map(data =>
      resultado.push({
        id: data['id'],
        title:
          data[campo] +
          delimitador1 +
          (campo2 !== null ? data[campo2] : '') +
          delimitador2 +
          (campo3 !== null ? data[campo3] : '') +
          delimitador3 +
          (campo4 !== null ? data[campo4] : ''),
      })
    );
    return resultado;
  }
  async ajustaColunasSelect(array) {
    let resultado = [];
    array.map(data =>
      resultado.push({
        id: data['value'],
        title: data['label'],
      })
    );
    return resultado;
  }
  async ajustaColunasList(array, campo1, campo2) {
    let resultado = [];
    array.map(data =>
      resultado.push({
        id: data[campo1],
        title: data[campo2],
      })
    );
    return resultado;
  }
}

export default new Selects();
