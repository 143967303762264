import styled from 'styled-components';

export const Body = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    margin-left: 7px;
    margin-right: 7px;
  }
`;

export const BodyLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Body404 = styled.p`
  font-weight: bold;
  font-size: 70px;
  color: #585858;
`;

export const BodyL2 = styled.p`
  font-size: 24px;
  color: #585858;
`;
