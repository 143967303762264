import React, { useState } from 'react';
import { toast } from 'react-toastify';

import masks from '../../lib/masks';
import { Title, Back, Form } from './styles';
import apiAuth from '../../services/apiAuth';

function Registro({ history }) {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [empresa, setEmpresa] = useState('');

  async function send() {
    if (nome === '' || nome === null || nome === undefined) {
      toast.error('Nome é obrigatório');
      document.getElementById('nome').focus();
      return null;
    }
    if (email === '' || email === null || email === undefined) {
      toast.error('E-mail é obrigatório');
      document.getElementById('email').focus();
      return null;
    }
    if (telefone === '' || telefone === null || telefone === undefined) {
      toast.error('Telefone é obrigatório');
      document.getElementById('telefone').focus();
      return null;
    }

    // envia email
    apiAuth.post('/registro', {
      nome,
      email,
      telefone,
      empresa,
    });

    // limpa formulario
    setNome('');
    setEmail('');
    setTelefone('');
    setEmpresa('');

    toast.success(
      'Solicitação efetuada com sucesso, em breve você será contatado'
    );
  }

  function digitar(e) {
    let valor;
    // preencheu um INPUT
    switch (e.target.name) {
      case 'telefone':
        valor = masks.fone(e.target.value);
        setTelefone(valor);
        break;
      case 'nome':
        setNome(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'empresa':
        setEmpresa(e.target.value);
        break;
      default:
        valor = e.target.value;
    }
  }

  return (
    <>
      <Title>
        Preencha o formulário abaixo para solicitar acesso ao sistema
      </Title>
      <Form onSubmit={null}>
        <input
          id="nome"
          name="nome"
          value={nome}
          onChange={digitar}
          placeholder="Seu nome"
        />
        <input
          id="email"
          name="email"
          value={email}
          onChange={digitar}
          placeholder="Seu e-mail"
        />
        <input
          id="telefone"
          name="telefone"
          maxLength={15}
          value={telefone}
          onChange={digitar}
          placeholder="Seu telefone"
        />
        <input
          name="empresa"
          value={empresa}
          onChange={digitar}
          placeholder="Sua empresa"
        />
        <button onClick={send}>Solicitar acesso</button>
      </Form>
      <Back onClick={() => history.push('/')}>Voltar</Back>
    </>
  );
}

export default Registro;
