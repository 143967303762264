import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  FaPrint,
  FaSearch,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';

import history from '../../../services/history';
import order from '../../../lib/orders';
import relatorio from '../../../lib/relatorios';
import api from '../../../services/api';
import {
  Container,
  Titulo,
  TituloBotao,
  Corpo,
  CorpoFiltro,
  CorpoTabela,
  TabelaTd,
  TabelaTh,
  CorpoFiltroInput,
  CorpoFiltroBotao,
  CorpoPaginacao,
  TabelaTdIcone,
} from '../../../styles/listagem';

export default function Produto() {
  // States
  const [filtroCodigos, setFiltroCodigos] = useState();
  const [filtroDescricao, setFiltroDescricao] = useState();
  const [filtroGrupo, setFiltroGrupo] = useState();
  const [filtroMarca, setFiltroMarca] = useState();

  const [entidades, setEntidades] = useState([]);
  const [paginas, setPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [registros, setRegistros] = useState(1);
  const [ordem, setOrdem] = useState({ campo: 'cidade', desc: false });

  // Acha Entidade
  async function carregar() {
    const response = await api.get('/item', {
      params: {
        fabricabarras: filtroCodigos,
        descricao: filtroDescricao,
        grupo: filtroGrupo,
        marca: filtroMarca,
      },
    });
    await setOrdem({ campo: 'descricao', desc: false });
    await setEntidades(response.data);
    await setRegistros(response.data.length);
    await setPaginas(Math.ceil(response.data.length / 10));
    await setPaginaAtual(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
  }

  // Ordena a entidade
  async function ordenar(campo) {
    ordem.campo === campo
      ? setOrdem({ campo, desc: !ordem.desc })
      : setOrdem({ campo, desc: false });
    const o =
      ordem.campo === campo
        ? { campo, desc: !ordem.desc }
        : { campo, desc: false };
    const result =
      o.campo === 'grupo' || o.campo === 'marca'
        ? await order.ordenarNull(entidades, o)
        : o.campo === 'quantidade'
        ? await order.ordenarInt(entidades, o)
        : o.campo === 'precovenda' || o.campo === 'precoavista'
        ? await order.ordenarNumeric(entidades, o)
        : await order.ordenarStr(entidades, o);
    setEntidades([]);
    setEntidades(result);
  }
  function ordenarCor(c, upDown) {
    return ordem.campo === c && ordem.desc === upDown ? '#585858' : '#dedcdc';
  }

  // Form show
  useEffect(() => {
    setFiltroCodigos();
    setFiltroDescricao();
    setFiltroGrupo();
    setFiltroMarca();
    carregar();
    // eslint-disable-next-line
  }, []);

  // Digitacao dos inputs
  function digitarCodigos(e) {
    if (e.target.value !== '') {
      setFiltroCodigos(e.target.value);
    } else {
      setFiltroCodigos();
    }
  }
  function digitarDescricao(e) {
    if (e.target.value !== '') {
      setFiltroDescricao(e.target.value);
    } else {
      setFiltroDescricao();
    }
  }
  function digitarGrupo(e) {
    if (e.target.value !== '') {
      setFiltroGrupo(e.target.value);
    } else {
      setFiltroGrupo();
    }
  }
  function digitarMarca(e) {
    if (e.target.value !== '') {
      setFiltroMarca(e.target.value);
    } else {
      setFiltroMarca();
    }
  }

  // Controle de paginacao
  function digitarPaginaMenos() {
    if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1);
  }
  function digitarPaginaMais() {
    if (paginaAtual !== paginas) setPaginaAtual(paginaAtual + 1);
  }
  function corIconePaginacaoIni() {
    if (paginaAtual <= 1) return '#f8f8f8';
    else return '#029f74';
  }
  function corIconePaginacaoFim() {
    if (paginas === paginaAtual) return '#f8f8f8';
    else return '#029f74';
  }
  function paginacao(p) {
    if (p === 1) return (paginaAtual - 1) * 10;
    else return paginaAtual * 10;
  }

  // Inclusao, alteracao e exclusao
  async function consultar(e) {
    await history.push(`produtos-cadastro?id=${e}`);
  }
  function imprimir() {
    const cabecalho =
      '<th>DESCRIÇÃO</th><th>GRUPO</th><th>MARCA</th><th>QUANTIDADE</th><th>A PRAZO</th><th>A VISTA</th>';
    let conteudo = '';
    entidades.map(
      e =>
        (conteudo =
          conteudo +
          `<tr key=${e.id}> ` +
          `<td>${e.descricao}</td>  ` +
          `<td>${e.grupo === null ? '' : e.grupo}</td> ` +
          `<td>${e.marca === null ? '' : e.marca}</td> ` +
          `<td>${
            e.quantidade === null ? '0,0000' : e.quantidade.replace('.', ',')
          } ${e.unidadevenda === null ? '' : e.unidadevenda}</td> ` +
          `<td>${
            e.precovenda === null ? '' : `R$ ${e.precovenda.replace('.', ',')}`
          }</td> ` +
          `<td>${
            e.precoavista === null
              ? ''
              : `R$ ${e.precoavista.replace('.', ',')}`
          }</td> ` +
          `</tr>`)
    );
    relatorio.relatorio('RELAÇÃO DE PRODUTOS', cabecalho, conteudo);
  }

  // Render da tela
  return (
    <Container>
      <Titulo>
        <strong>LISTAGEM DE PRODUTOS</strong>
        <div>
          <TituloBotao onClick={imprimir}>
            <div>
              <FaPrint size={18} color={'#029f74'} />
            </div>
            Imprimir
          </TituloBotao>
        </div>
      </Titulo>
      <Corpo>
        <CorpoFiltro>
          <CorpoFiltroInput
            onInput={digitarCodigos}
            size={20}
            placeholder="Filtre pelos códigos"
          />
          <CorpoFiltroInput
            onInput={digitarDescricao}
            size={40}
            placeholder="Filtre pela descrição"
          />
          <CorpoFiltroInput
            onInput={digitarGrupo}
            size={20}
            placeholder="Filtre pelo grupo"
          />
          <CorpoFiltroInput
            onInput={digitarMarca}
            size={20}
            placeholder="Filtre pela marca"
          />
          <CorpoFiltroBotao onClick={carregar}>
            <div>
              <FaSearch size={18} color={'#fff'} />
            </div>
            {/**Filtrar*/}
          </CorpoFiltroBotao>
        </CorpoFiltro>
        <CorpoTabela id="tabela">
          <table>
            <thead>
              <tr>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('descricao');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('descricao', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('descricao', true)}
                    />
                  </button>
                  Descrição
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('grupo');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('grupo', false)} />
                    <FaArrowDown size={14} color={ordenarCor('grupo', true)} />
                  </button>
                  Grupo
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('marca');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('marca', false)} />
                    <FaArrowDown size={14} color={ordenarCor('marca', true)} />
                  </button>
                  Marca
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('quantidade');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('quantidade', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('quantidade', true)}
                    />
                  </button>
                  Quantidade
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('precoavista');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('precoavista', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('precoavista', true)}
                    />
                  </button>
                  A vista
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('precovenda');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('precovenda', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('precovenda', true)}
                    />
                  </button>
                  A prazo
                </TabelaTh>
                <TabelaTh last={true} center={true}>
                  Ações
                </TabelaTh>
              </tr>
            </thead>
            <tbody>
              {entidades.slice(paginacao(1), paginacao(2)).map(e => (
                <tr key={e.id}>
                  <TabelaTd width={'31%'}>{e.descricao}</TabelaTd>
                  <TabelaTd width={'13%'}>{e.grupo}</TabelaTd>
                  <TabelaTd width={'13%'}>{e.marca}</TabelaTd>
                  <TabelaTd width={'12%'} center={true}>
                    {`${
                      e.quantidade === null
                        ? '0,0000'
                        : e.quantidade.replace('.', ',')
                    } ${e.unidadevenda === null ? '' : e.unidadevenda}`}
                  </TabelaTd>
                  <TabelaTd width={'12%'}>
                    R$ {e.precoavista.replace('.', ',')}
                  </TabelaTd>
                  <TabelaTd width={'12%'}>
                    R$ {e.precovenda.replace('.', ',')}
                  </TabelaTd>
                  <TabelaTd width={'7%'} center={true}>
                    <TabelaTdIcone
                      title="Clique para consultar os dados do produto"
                      onClick={() => {
                        consultar(e.id);
                      }}
                    >
                      <FaSearch size={18} color={'#585858'} />
                    </TabelaTdIcone>
                  </TabelaTd>
                </tr>
              ))}
            </tbody>
          </table>
        </CorpoTabela>
        <CorpoPaginacao>
          <span>{registros} registro(s) encontrado(s)</span>
          <div>
            <button onClick={digitarPaginaMenos}>
              <FaChevronCircleLeft size={26} color={corIconePaginacaoIni()} />
            </button>
            <strong>
              {paginaAtual}/{paginas}
            </strong>
            <button onClick={digitarPaginaMais}>
              <FaChevronCircleRight size={26} color={corIconePaginacaoFim()} />
            </button>
          </div>
        </CorpoPaginacao>
      </Corpo>
    </Container>
  );
}
