import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  FaPlus,
  FaPrint,
  FaPencilAlt,
  FaTrash,
  FaSearch,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';

import { Confirmation } from '../../../styles/confirmation';
import history from '../../../services/history';
import order from '../../../lib/orders';
import relatorio from '../../../lib/relatorios';
import api from '../../../services/api';
import permissions from '../../../lib/permissions';

import {
  Container,
  Titulo,
  TituloBotao,
  Corpo,
  CorpoFiltro,
  CorpoTabela,
  TabelaTd,
  TabelaTdComTag,
  TabelaTdTag,
  TabelaTh,
  CorpoFiltroInput,
  CorpoFiltroField,
  CorpoFiltroBotao,
  CorpoPaginacao,
  TabelaTdIcone,
} from '../../../styles/listagem';

export default function Cliente() {
  //
  // States
  const [filtroInativos, setFiltroInativos] = useState(false);
  const [filtroCodigo, setFiltroCodigo] = useState();
  const [filtroNome, setFiltroNome] = useState();

  const [entidades, setEntidades] = useState([]);
  const [paginas, setPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [registros, setRegistros] = useState(1);
  const [ordem, setOrdem] = useState({ campo: 'razaosocial', desc: false });

  // Acha Entidade
  async function carregar() {
    // Permissões
    const colaboradorid = await permissions.visualizaGeral();
    // Filtro e popula
    const inativos = filtroInativos === true ? true : undefined;
    const response = await api.get('/cliente', {
      params: {
        codigo: filtroCodigo,
        nome: filtroNome,
        todosativoinativo: inativos,
        colaboradorid: colaboradorid === null ? undefined : colaboradorid,
      },
    });
    await setOrdem({ campo: 'razaosocial', desc: false });
    await setEntidades(response.data);
    await setRegistros(response.data.length);
    await setPaginas(Math.ceil(response.data.length / 10));
    await setPaginaAtual(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
  }

  // Ordena a entidade
  async function ordenar(campo) {
    ordem.campo === campo
      ? setOrdem({ campo, desc: !ordem.desc })
      : setOrdem({ campo, desc: false });
    const o =
      ordem.campo === campo
        ? { campo, desc: !ordem.desc }
        : { campo, desc: false };
    const result =
      o.campo === 'id'
        ? await order.ordenarInt(entidades, o)
        : o.campo === 'telefone1' || o.campo === 'email1'
        ? await order.ordenarNull(entidades, o)
        : await order.ordenarStr(entidades, o);
    setEntidades([]);
    setEntidades(result);
  }
  function ordenarCor(c, upDown) {
    return ordem.campo === c && ordem.desc === upDown ? '#585858' : '#dedcdc';
  }

  // Form show
  useEffect(() => {
    setFiltroCodigo();
    setFiltroNome();
    setFiltroInativos(false);
    carregar();
    // eslint-disable-next-line
  }, []);

  // Digitacao dos inputs
  function digitarNome(e) {
    if (e.target.value !== '') {
      setFiltroNome(e.target.value);
    } else {
      setFiltroNome();
    }
  }
  function digitarInativos(e) {
    setFiltroInativos(e.target.checked);
  }

  // Controle de paginacao
  function digitarPaginaMenos() {
    if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1);
  }
  function digitarPaginaMais() {
    if (paginaAtual !== paginas) setPaginaAtual(paginaAtual + 1);
  }
  function corIconePaginacaoIni() {
    if (paginaAtual <= 1) return '#f8f8f8';
    else return '#029f74';
  }
  function corIconePaginacaoFim() {
    if (paginas === paginaAtual) return '#f8f8f8';
    else return '#029f74';
  }
  function paginacao(p) {
    if (p === 1) return (paginaAtual - 1) * 10;
    else return paginaAtual * 10;
  }

  // Inclusao, alteracao e exclusao
  async function adicionar() {
    await history.push('clientes-cadastro');
  }
  async function editar(e) {
    await history.push(`clientes-cadastro?id=${e}`);
  }
  async function deletar(e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Confirma a exclusão do cliente?</p>
            <div>
              <button
                onClick={async () => {
                  const propostas = await api.get('/proposta', {
                    params: {
                      clienteid: e,
                    },
                  });
                  if (propostas.data.length > 0) {
                    toast.error(
                      `Cliente vinculado a ${propostas.data.length} proposta(s), não é possível excluir!`
                    );
                  } else {
                    const response = await api.delete(`/cliente/${e}`);
                    if (response.status === 200) {
                      toast.success('Cliente deletado com sucesso!');
                    } else {
                      toast.error(
                        'Problemas ao deletar cliente, tente novamente!'
                      );
                    }
                    carregar();
                  }
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
  }
  function imprimir() {
    const cabecalho =
      '<th>ID</th><th>NOME</th><th>TELEFONE</th><th>EMAIL</th><th>INATIVO</th>';
    let conteudo = '';
    entidades.map(
      cliente =>
        (conteudo =
          conteudo +
          `<tr key=${cliente.id}> ` +
          `<td>${cliente.id}</td>  ` +
          `<td>${cliente.razaosocial}</td> ` +
          `<td>${cliente.telefone1 === null ? '' : cliente.telefone1}</td> ` +
          `<td>${cliente.email1 === null ? '' : cliente.email1}</td> ` +
          `<td>${
            cliente.datainativo === null ? '' : cliente.datainativo
          }</td> ` +
          `</tr>`)
    );
    relatorio.relatorio('RELAÇÃO DE CLIENTES', cabecalho, conteudo);
  }

  // Render da tela
  return (
    <Container>
      <Titulo>
        <strong>LISTAGEM DE CLIENTES</strong>
        <div>
          <TituloBotao destaque={true} onClick={adicionar}>
            <div>
              <FaPlus size={18} color={'#fff'} />
            </div>
            Adicionar
          </TituloBotao>
          <TituloBotao onClick={imprimir}>
            <div>
              <FaPrint size={18} color={'#029f74'} />
            </div>
            Imprimir
          </TituloBotao>
        </div>
      </Titulo>
      <Corpo>
        <CorpoFiltro>
          <CorpoFiltroInput
            onInput={digitarNome}
            size={80}
            placeholder="Filtre pelo nome"
          />
          <CorpoFiltroField size={20}>
            <input
              type="checkbox"
              onChange={digitarInativos}
              defaultValue={filtroInativos}
            />
            <strong>Listar inativos</strong>
          </CorpoFiltroField>
          <CorpoFiltroBotao onClick={carregar}>
            <div>
              <FaSearch size={18} color={'#fff'} />
            </div>
          </CorpoFiltroBotao>
        </CorpoFiltro>
        <CorpoTabela id="tabela">
          <table>
            <thead>
              <tr>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('id');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('id', false)} />
                    <FaArrowDown size={14} color={ordenarCor('id', true)} />
                  </button>
                  Cod.
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('razaosocial');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('razaosocial', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('razaosocial', true)}
                    />
                  </button>
                  Nome
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('telefone1');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('telefone1', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('telefone1', true)}
                    />
                  </button>
                  Telefone
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('email1');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('email1', false)} />
                    <FaArrowDown size={14} color={ordenarCor('email1', true)} />
                  </button>
                  E-mail
                </TabelaTh>
                <TabelaTh center={true}>AÇÕES</TabelaTh>
              </tr>
            </thead>
            <tbody>
              {entidades.slice(paginacao(1), paginacao(2)).map(e => (
                <tr key={e.id}>
                  <TabelaTd width={'8%'} center={true}>
                    {e.id}
                  </TabelaTd>
                  <TabelaTd width={'40%'}>
                    <TabelaTdComTag>
                      {e.razaosocial}
                      {e.datainativo !== null ? (
                        <TabelaTdTag background={'#ff4d4d'} color={'#fff'}>
                          Inativo
                        </TabelaTdTag>
                      ) : null}
                    </TabelaTdComTag>
                  </TabelaTd>
                  <TabelaTd width={'14%'}>{e.telefone1}</TabelaTd>
                  <TabelaTd width={'26%'}>{e.email1}</TabelaTd>
                  <TabelaTd width={'12%'} center={true}>
                    <TabelaTdIcone
                      title="Clique para editar o cliente"
                      onClick={() => {
                        editar(e.id);
                      }}
                    >
                      <FaPencilAlt size={18} color={'#585858'} />
                    </TabelaTdIcone>
                    <TabelaTdIcone
                      title="Clique para deletar o cliente"
                      onClick={() => {
                        deletar(e.id);
                      }}
                    >
                      <FaTrash size={18} color={'#d13337'} />
                    </TabelaTdIcone>
                  </TabelaTd>
                </tr>
              ))}
            </tbody>
          </table>
        </CorpoTabela>
        <CorpoPaginacao>
          <span>{registros} registro(s) encontrado(s)</span>
          <div>
            <button onClick={digitarPaginaMenos}>
              <FaChevronCircleLeft size={26} color={corIconePaginacaoIni()} />
            </button>
            <strong>
              {paginaAtual}/{paginas}
            </strong>
            <button onClick={digitarPaginaMais}>
              <FaChevronCircleRight size={26} color={corIconePaginacaoFim()} />
            </button>
          </div>
        </CorpoPaginacao>
      </Corpo>
    </Container>
  );
}
