import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { FaGooglePlay, FaAppStore } from 'react-icons/fa';

import {
  Container,
  Header,
  HeaderImg,
  Body,
  BodyDescribe,
  Button,
  ButtonText,
  Tag,
  Cadastrar,
} from './styles';
import api from '../../services/apiAuth';
import logo from '../../assets/easy2_branco.png';

const schema = Yup.object().shape({
  nome: Yup.string().required('* O usuário é obrigatório'),
  senha: Yup.string().required('* A senha é obrigatória'),
});

export default function Login({ history }) {
  // Funcoes
  async function logar({ nome, senha }) {
    try {
      const response = await api.post('/sessao', { nome, senha });
      await localStorage.setItem('@3reasy:JWT_TOKEN', response.data.token);
      await localStorage.setItem('@3reasy:USER_ID', response.data.user.id);
      await localStorage.setItem('@3reasy:USER_NAME', response.data.user.nome);
      await localStorage.setItem(
        '@3reasy:USER_VG',
        response.data.user.visualizageral
      );
      await localStorage.setItem(
        '@3reasy:USER_VC',
        response.data.user.visualizacusto
      );
      await history.push('dashboard');
    } catch (err) {
      toast.error('Usuário ou senha inválida');
    }
  }

  // Render dos componentes
  return (
    <>
      {isMobile ? (
        <Container>
          <Header>
            <HeaderImg height="40" src={logo} alt="Easy" />
          </Header>
          <Body>
            <BodyDescribe>
              O easy está disponível para download na loja de seu smartphone.
            </BodyDescribe>
            <Button href={'android-app://com.rsistemas.easy_app'}>
              <FaGooglePlay size={40} color={'#fff'} />
              <ButtonText>
                <p>Android</p>
                <p>Download na Google Play</p>
              </ButtonText>
            </Button>
            <Button disabled>
              <FaAppStore size={40} color={'#fff'} />
              <ButtonText>
                <p>
                  iOS <Tag>* Em breve</Tag>
                </p>
                <p>Download na App Store</p>
              </ButtonText>
            </Button>
          </Body>
        </Container>
      ) : (
        <>
          <img src={logo} alt="Easy" />
          <Form schema={schema} onSubmit={logar}>
            <Input name="nome" type="text" placeholder="Seu usuário" />
            <Input name="senha" type="password" placeholder="Sua senha" />
            <button type="submit">Login</button>
          </Form>
          <Cadastrar onClick={() => history.push('/registro')}>
            Não tem uma conta ? Solicite acesso.
          </Cadastrar>
        </>
      )}
    </>
  );
}
