import styled from 'styled-components';
import { darken } from 'polished';

import DatePicker from 'react-datepicker';

/* ************* CONTAINER ************* */

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #eaeaea;
  overflow: auto;
`;

/* ************* TITULO ************* */
export const Titulo = styled.div`
  max-width: 95%;
  margin: 20px auto 0px auto;
  border-radius: 9px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  strong {
    font-size: 16px;
    font-weight: bold;
    color: #585858;
  }
  div {
    display: flex;
    flex-direction: row;
  }
`;

export const TituloBotao = styled.button`
  background: ${props => (props.destaque ? '#029f74' : '#fff')};
  border-radius: 5px;
  width: 150px;
  height: 35px;
  border: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${props => (props.destaque ? '#fff' : '#029f74')};
  margin-left: 5px;
  font-weight: bold;
  div {
    margin-right: 7px;
  }
  &:hover {
    background: ${props =>
      props.destaque
        ? `${darken(0.05, '#029f74')}`
        : `${darken(0.05, '#FFF')}`};
  }
`;

/* ************* CORPO ************* */

export const Corpo = styled.div`
  max-width: 95%;
  margin: 5px auto 20px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CorpoFiltro = styled.div`
  width: 100%;
  height: 50px;
  background: #fbfbfb;
  border-top-left-radius: ${props => (props.second ? '0' : '5px')};
  border-top-right-radius: ${props => (props.second ? '0' : '5px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: ${props => (props.second ? '0' : '10px')};
  strong {
    color: #585858;
    margin-left: 10px;
    margin-right: 3px;
  }
  span {
    color: #585858;
    margin-right: 5px;
  }
`;

export const CorpoFiltroInput = styled.input`
  font-size: 14px;
  width: ${props => (props.size ? `${props.size}%` : '300px')};
  height: 35px;
  margin-right: ${props => (props.last ? '0' : '7px')};
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 7px;
  border-color: #e6e6e6;
`;

export const CorpoFiltroField = styled.div`
  font-size: 13px;
  width: ${props => (props.size ? `${props.size}%` : '300px')};
  height: 35px;
  margin-right: ${props => (props.last ? '0' : '7px')};
  border-radius: 5px;
  border-style: solid;
  border-width: ${props => (props.date ? '0' : '1px')};
  padding: ${props => (props.date ? '0' : '7px')};
  border-color: #e6e6e6;
  background: ${props => (props.date ? 'transparent' : '#FFF')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > div {
    /** Para datas ficarem full no resize */
    width: 100%;
  }
`;

export const CorpoFiltroSelect = styled.div`
  font-size: 13px;
  width: ${props => (props.size ? `${props.size}%` : '300px')};
  height: 35px;
  padding-right: 7px;
  background: ${props => (props.date ? 'transparent' : '#FFF')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > div {
    width: 100%;
  }
`;

export const CorpoFiltroDate = styled(DatePicker)`
  border-color: #e6e6e6;
  border-style: solid;
  margin-right: 7px;
  border-width: 1px;
  border-radius: 5px;
  width: 100%; /*${props => (props.size ? `${props.size}%` : '300px')};*/
  height: 35px;
  padding: 0 7px;
`;

export const CorpoFiltroBotao = styled.button`
  background: #029f74;
  border-radius: 5px;
  width: 80px;
  min-width: 80px;
  height: 35px;
  border: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  font-weight: bold;
  &:hover {
    background: ${darken(0.05, '#029f74')};
  }
`;

export const CorpoTabela = styled.div`
  width: 100%;
  background: #fff;
  table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #f1f1f1;
    thead {
      height: 100%;
      width: 100%;
    }
    tbody {
      height: 100%;
      width: 100%;
      tr {
        height: 45px;
        &:nth-child(2n + 2) {
          background: #f8f8f8;
        }
        &:hover {
          background: ${darken(0.04, '#f3f3f3')};
        }
      }
    }
  }
`;

export const TabelaTh = styled.th`
  text-align: ${props => (props.center ? 'center' : 'left')};
  padding: 7px;
  background: #f1f1f1; /**#029f74*/
  height: 38px;
  font-weight: bold;
  color: #029f74;
  border-top: 1px solid #dedcdc;
  border-right: ${props => (props.last ? null : '1px solid #dedcdc')};
  border-bottom: 2px solid #dedcdc;
  button {
    border: 0;
    background-color: transparent;
    margin-right: 5px;
  }
`;

export const TabelaTd = styled.td`
  flex-direction: row;
  border-left: 1px solid #f1f1f1;
  padding: 7px;
  color: ${props =>
    props.finalizado ? '#029f74' : props.cancelado ? '#d13337' : 'black'};
  text-align: ${props => (props.center ? 'center' : 'left')};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  border: ${props => (props.backgroundColor ? '1px solid #fff' : null)};
`;

export const TabelaTdCenter = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TabelaTdComTag = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TabelaTdTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.background ? props.background : '#e6e6e6')};
  color: ${props => (props.color ? props.color : '#585858')};
  font-weight: bold;
  width: 60px;
  height: 17px;
  border-radius: 2px;
  font-size: 11px;
  margin-left: 8px;
`;

export const TabelaTdIcone = styled.button`
  margin-right: 5px;
  border: 0;
  background: #f1f1f1;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  padding-top: 3px;
`;

export const TabelaTdBotao = styled.button`
  background: #029f74;
  border-radius: 2px;
  width: 50px;
  height: 25px;
  border: 0;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  margin-right: 5px;
  font-weight: bold;
  div {
    margin-right: 2px;
  }
`;

export const CorpoPaginacao = styled.div`
  width: 100%;
  height: 45px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid #f1f1f1;
  span {
    margin-left: 20px;
    font-size: 12px;
    color: #585858;
  }

  div {
    display: flex;
    flex-direction: row;
    margin-right: 25px;
    align-items: center;
    justify-content: center;
    height: 25px;
    strong {
      margin-left: 10px;
      margin-right: 10px;
      height: 25px;
      padding-top: 3px;
    }
    button {
      border: 0;
      background: transparent;
    }
  }
`;
