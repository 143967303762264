import React, { useEffect, useState } from 'react';
import { FaDoorOpen } from 'react-icons/fa';
import 'react-confirm-alert/src/react-confirm-alert.css';

import api from '../../../services/api';
import permissions from '../../../lib/permissions';
import {
  Container,
  Titulo,
  Corpo,
  CorpoForm,
  CorpoFormButton,
  CorpoLinha,
  CorpoLinhaCampo,
  Input,
  Label,
} from '../../../styles/cadastro';
import history from '../../../services/history';

export default function ProdutoCadastro() {
  // States
  const [visualizaCusto, setVisualizaCusto] = useState('0');
  const [, setId] = useState();
  const [entidade, setEntidade] = useState({
    codigofabrica: null,
    codigobarras: null,
    descricao: null,
    grupo: null,
    marca: null,
    quantidade: null,
    estoqueminimo: null,
    precocusto: null,
    precovenda: null,
    precoavista: null,
    unidadevenda: null,
    pesobruto: null,
    pesoliquido: null,
  });

  // Form Show
  useEffect(() => {

    async function carregaPermissoes() {
      const vc = await permissions.visualizaCusto();
      setVisualizaCusto(vc);
    }
    carregaPermissoes();

    // Pega o ID quando edicao
    let i = [];
    const urn = window.location.href.split('?');
    if (urn[1] !== undefined) {
      i = urn[1].split('=');
      setId(i[1]);
    }
    // Consulta dados do ID quando edicao
    if (i[1] !== undefined) {
      async function popularEntidade() {
        let e = await api.get(`item/${i[1]}`);
        setEntidade(e.data);
      }
      popularEntidade();
    }
  }, []); // eslint-disable-next-line

  // Grava o cadastro e fecha formulario
  async function concluir(e) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();
    history.push('produtos');
  }

  // Renderiza o codigo em tela
  return (
    <Container>
      <Titulo>
        <strong>CONSULTA DE PRODUTOS</strong>
      </Titulo>
      <Corpo>
        <CorpoForm autoComplete="off" onSubmit={concluir}>
          <CorpoLinha border={true}>
            <strong>CODIGOS</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={33.333}>
              <Label>Codigo sequencial</Label>
              <Input disabled name="id" value={entidade.id} />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label>Codigo de fábrica</Label>
              <Input
                disabled
                name="codigofabrica"
                value={entidade.codigofabrica}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label>Codigo de barras</Label>
              <Input
                disabled
                name="codigobarras"
                value={entidade.codigobarras}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>DADOS PRINCIPAIS</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={100}>
              <Label>Descrição</Label>
              <Input disabled name="descricao" value={entidade.descricao} />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label>Grupo</Label>
              <Input disabled name="grupo" value={entidade.grupo} />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={50}>
              <Label>Marca</Label>
              <Input disabled name="marca" value={entidade.marca} />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>ESTOQUES</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label>Quantidade</Label>
              <Input
                disabled
                name="quantidade"
                value={`${
                  entidade.quantidade === null
                    ? '0,00'
                    : entidade.quantidade.replace('.', ',')
                } ${
                  entidade.unidadevenda === null ? '' : entidade.unidadevenda
                }`}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={50}>
              <Label>Estoque mínimo</Label>
              <Input
                disabled
                name="estoqueminimo"
                value={`${
                  entidade.estoqueminimo === null
                    ? '0,00'
                    : entidade.estoqueminimo.replace('.', ',')
                } ${
                  entidade.unidadevenda === null ? '' : entidade.unidadevenda
                }`}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>PREÇOS</strong>
          </CorpoLinha>
          <CorpoLinha>
            {visualizaCusto==='1' && (
              <CorpoLinhaCampo size={33.333}>
                <Label>Preço de custo</Label>
                <Input
                  disabled
                  name="precocusto"
                  value={`R$ ${
                    entidade.precocusto > 0
                      ? entidade.precocusto.replace('.', ',')
                      : '0,00'
                  }`}
                />
              </CorpoLinhaCampo>
            )}
            <CorpoLinhaCampo size={visualizaCusto === '0' ? 50 : 33.333}>
              <Label>Preço a vista</Label>
              <Input
                disabled
                name="Preço a vista"
                value={`R$ ${
                  entidade.precoavista > 0
                    ? entidade.precoavista.replace('.', ',')
                    : '0,00'
                }`}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={visualizaCusto === '0' ? 50 : 33.333}>
              <Label>Preço a prazo</Label>
              <Input
                disabled
                name="Preço a prazo"
                value={`R$ ${
                  entidade.precovenda > 0
                    ? entidade.precovenda.replace('.', ',')
                    : '0,00'
                }`}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>PESOS</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label>Peso bruto</Label>
              <Input disabled name="pesobruto" value={entidade.pesobruto} />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={50}>
              <Label>Peso líquido</Label>
              <Input disabled name="pesoliquido" value={entidade.pesoliquido} />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <div>
            <CorpoFormButton type="submit">
              <div>
                <FaDoorOpen size={18} color={'#fff'} />
              </div>
              Voltar
            </CorpoFormButton>
          </div>
        </CorpoForm>
      </Corpo>
    </Container>
  );
}
