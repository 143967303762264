import React, { useState } from 'react';
import history from '../../services/history';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaCog, FaUser, FaDoorOpen } from 'react-icons/fa';

import { Confirmation } from '../../styles/confirmation';
import { Header, Options } from './styles';

export default function HeaderPage() {
  const [menu, setMenu] = useState(false);

  async function parametros() {
    toast.warn('Funcionalidade em desenvolvimento');
  }

  async function perfil() {
    toast.warn('Funcionalidade em desenvolvimento');
  }

  async function sair() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Deseja sair do sistema ?</p>
            <div>
              <button
                onClick={async () => {
                  await localStorage.removeItem('@3reasy:JWT_TOKEN');
                  await localStorage.removeItem('@3reasy:USER_ID');
                  await localStorage.removeItem('@3reasy:USER_NAME');
                  await localStorage.removeItem('@3reasy:USER_VG');
                  await history.push('/');
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
  }

  return (
    <Header>
      <Options>
        <button
          title="Clique para acessar opções do usuário"
          onClick={() => perfil()}
        >
          <FaUser size={18} color={'#fff'} />
        </button>
        <button
          title="Clique para acessar as configurações"
          onClick={() => parametros()}
        >
          <FaCog size={20} color={'#fff'} />
        </button>
        <button title="Clique para sair do sistema" onClick={() => sair()}>
          <FaDoorOpen size={20} color={'#fff'} />
        </button>
      </Options>
    </Header>
  );
}
