import React, { useEffect, useState } from 'react';
import {
  FaSave,
  FaTimes,
  FaPlusCircle,
  FaPencilAlt,
  FaTrash,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Select from '../../../components/Select';
import masks from '../../../lib/masks';
import values from '../../../lib/values';
import forms from '../../../lib/forms';
import api from '../../../services/api';
import dates from '../../../lib/dates';
import selects from '../../../lib/selects';
import permissions from '../../../lib/permissions';
import {
  Container,
  Titulo,
  Corpo,
  CorpoForm,
  CorpoFormButton,
  CorpoLinha,
  CorpoTabela,
  CorpoTabelaTd,
  CorpoTabelaIcone,
  CorpoTabelaAdicionar,
  CorpoLinhaCampo,
  CorpoFooter,
  Input,
  InputMulti,
  DateP,
  Label,
} from '../../../styles/cadastro';
import { Confirmation } from '../../../styles/confirmation';
import history from '../../../services/history';

export default function PropostaCadastro() {
  // States
  const [id, setId] = useState();
  const [entidade, setEntidade] = useState({
    data: null,
    cliente_id: null,
    colaborador_id: null,
    tipo: null,
    precoaprazoavista: null,
    valorproduto: null,
    valoroutros: null,
    valortotal: null,
    obs: null,
  });
  const [entidadeValidacao, setEntidadeValidacao] = useState({
    data: true,
    cliente_id: true,
    colaborador_id: true,
    tipo: true,
    precoaprazoavista: true,
    valorproduto: true,
    valoroutros: true,
    valortotal: true,
  });
  const [produtos, setProdutos] = useState();
  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [tipospagamento, setTipospagamento] = useState([]);
  const [colaboradorHabilitado, setColaboradorHabilitado] = useState(true);
  const [gra, setGra] = useState(true);

  // Form Show
  useEffect(() => {
    // Pega o ID quando edicao
    let i = [];
    const urn = window.location.href.split('?');
    if (urn[1] !== undefined) {
      i = urn[1].split('=');
      setId(i[1]);
    }
    // Faz a consulta dos campos de select
    popularSelects();
    // Faz a consulta se visualiza geral ou nao
    async function habilita() {
      setColaboradorHabilitado(
        (await permissions.visualizaGeral()) === null ? false : true
      );
    }
    habilita();
    // Consulta dados do ID quando edicao
    if (i[1] !== undefined) {
      async function popularEntidade() {
        let e = await api.get(`proposta/${i[1]}`);
        e.data.data !== null
          ? (e.data.data = await parseISO(
              `${dates.dataSQL(e.data.data)}T00:00:00`
            ))
          : (e.data.data = null);
        e.data.finalizado !== null
          ? (e.data.finalizado = await parseISO(
              `${dates.dataSQL(e.data.finalizado)}T00:00:00`
            ))
          : (e.data.finalizado = null);
        e.data.cancelado !== null
          ? (e.data.cancelado = await parseISO(
              `${dates.dataSQL(e.data.cancelado)}T00:00:00`
            ))
          : (e.data.cancelado = null);

        e.data.valorproduto = masks.valor(e.data.valorproduto);
        e.data.valoroutros = masks.valor(e.data.valoroutros);
        e.data.valortotal = masks.valor(e.data.valortotal);

        setEntidade(e.data);
      }
      popularEntidade();
      listaProdutos(i[1]);
    } else {
      async function populaColaborador() {
        // Inclusao
        setEntidade({
          data: new Date(),
          cliente_id: null,
          colaborador_id: await permissions.colaborador(),
          tipo: 'PEDIDO',
          precoaprazoavista: 'A VISTA',
          valorproduto: 0,
          valoroutros: 0,
          valortotal: 0,
          obs: null,
        });
      }
      populaColaborador();
    }
  }, []); // eslint-disable-next-line

  // Atualizar o total
  useEffect(() => {
    // console.log(entidade.valoroutros, entidade.valorproduto);

    let voutros = entidade.valoroutros;
    let vprodutos = entidade.valorproduto;

    if (
      voutros === null ||
      voutros === NaN ||
      voutros === 0 ||
      voutros === ''
    ) {
      voutros = parseFloat(0.0);
    } else {
      if (`${voutros}`.indexOf(',')) {
        voutros = parseFloat(`${voutros}`.replace(',', '.')).toFixed(2);
      } else {
        voutros = parseFloat(`${voutros}`).toFixed(2);
      }
    }

    if (
      vprodutos === null ||
      vprodutos === NaN ||
      vprodutos === 0 ||
      vprodutos === ''
    ) {
      vprodutos = parseFloat(0.0);
    } else {
      if (`${vprodutos}`.indexOf(',')) {
        vprodutos = parseFloat(`${vprodutos}`.replace(',', '.')).toFixed(2);
      } else {
        vprodutos = parseFloat(`${vprodutos}`).toFixed(2);
      }
    }

    setEntidade(
      forms.populaFormulario(
        entidade,
        'valortotal',
        parseFloat(parseFloat(voutros) + parseFloat(vprodutos)).toFixed(2)
      )
    );

    //console.log(voutros, vprodutos);
  }, [entidade.valoroutros]);

  // Popula todos o campos selects
  async function popularSelects() {
    const colaboradorid = await permissions.visualizaGeral();
    const c = await api.get('/select/cliente', {
      params: {
        colaboradorid: colaboradorid === null ? undefined : colaboradorid,
      },
    });
    setClientes(await selects.ajustaColunasSelect(c.data));
    const cb = await api.get('/select/colaborador');
    setColaboradores(await selects.ajustaColunasSelect(cb.data));
    setTipos([
      { title: 'ORÇAMENTO', id: 'ORCAMENTO' },
      { title: 'PEDIDO', id: 'PEDIDO' },
    ]);
    setTipospagamento([
      { title: 'A VISTA', id: 'A VISTA' },
      { title: 'A PRAZO', id: 'A PRAZO' },
    ]);
  }

  // Grava o cadastro, fecha formulario se for edicao
  async function gravar(e, fechar = true) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();
    if (gra === true) {
      setGra(false);
      // Valida os campos obrigatorios
      const [o, v] = forms.validaFormulario(entidade, entidadeValidacao);
      setEntidadeValidacao(o);
      if (!v) {
        setGra(true);
        return false;
      }
      //
      let ent = entidade;
      // Ajusta campos numericos
      ent.valortotal = await values.numeroBanco(ent.valortotal);
      ent.valoroutros = await values.numeroBanco(ent.valoroutros);
      ent.valorproduto = await values.numeroBanco(ent.valorproduto);
      ent.importar = 1;
      // Limpa campos em branco
      ent = forms.preparaFormulario(ent);
      // Editar
      if (id !== undefined) {
        const response = await api.put(`proposta/${id}`, ent);
        if (response.status === 200) {
          //toast.success('PROPOSTA EDITADA COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO EDITAR A PROPOSTA');
          setGra(true);
        }
        if (fechar) {
          toast.success('PROPOSTA ADICIONADA/EDITADA COM SUCESSO');
          history.push('propostas');
        }
      }
      // Incluir
      else {
        const response = await api.post('proposta', ent);
        if (response.status === 200) {
          //toast.success('PROPOSTA ADICIONADA COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO ADICIONAR A PROPOSTA');
          setGra(true);
        }
        setId(response.data.id);
        if (fechar) history.push(`propostas-cadastro?id=${response.data.id}`);
      }
      setGra(true);
    }
  }

  // Cancela o cadastro e fecha o formulario
  async function cancelar(e) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Confirma o cancelamento do cadastro?</p>
            <div>
              <button
                onClick={async () => {
                  toast.warn('CADASTRO CANCELADO');
                  history.push('propostas');
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
  }

  // Digita o campo no objeto e popula com seu devido valor
  function digitarSelect(e) {
    if (e === null) {
      setEntidade(forms.populaFormulario(entidade, this.name, null));
    } else {
      if (e.id !== undefined) {
        setEntidade(forms.populaFormulario(entidade, this.name, e.id));
      }
    }
  }
  function digitarInput(e) {
    let valor;
    switch (e.target.name) {
      case 'valoroutros':
        valor = masks.valor(e.target.value);
        break;
      default:
        valor = e.target.value;
    }
    setEntidade(forms.populaFormulario(entidade, e.target.name, valor));
  }
  function digitarInputOutros(e) {
    let valor = masks.valor(e.target.value);
    setEntidade(forms.populaFormulario(entidade, 'valoroutros', valor));
  }
  function digitarDate(e) {
    setEntidade(forms.populaFormulario(entidade, this.name, e));
  }

  // Faz a validacao no exit do campo
  function campoExit(campo) {
    setEntidadeValidacao(
      forms.validaFormularioCampo(entidade, entidadeValidacao, campo)
    );
  }

  // Escreve a label conforme parametros de validacao
  function campoLabel(label, field) {
    return `
      ${label}
      ${!entidadeValidacao[field] ? ' (Campo obrigatório)' : ''}
      `;
  }

  //
  async function adicionarProdutos(e) {
    gravar(e, false);
    e.preventDefault();
    // abre cad
    await history.push(
      `propostas-cadastro-produtos?proposta=${id}&preco=${
        entidade.precoaprazoavista === 'A VISTA' ? 1 : 2
      }`
    );
  }

  //
  async function editarProdutos(e, prod) {
    gravar(e, false);
    e.preventDefault();
    await history.push(
      `propostas-cadastro-produtos?id=${prod}&preco=${
        entidade.precoaprazoavista === 'A VISTA' ? 1 : 2
      }`
    );
    //...
  }

  //
  async function excluirProdutos(e, prod) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Confirma a exclusão do produto?</p>
            <div>
              <button
                onClick={async () => {
                  const response = await api.delete(`/propostaitem/${prod}`);
                  if (response.status === 200) {
                    toast.success('Produto deletado com sucesso!');
                  } else {
                    toast.error(
                      'Problemas ao deletar produto, tente novamente!'
                    );
                  }
                  listaProdutos(id);

                  // ATUALIZAR TOTAL DOS PRODUTOS
                  let total = await api.get(`/total/propostaitem/${id}`);
                  const totalProduto =
                    total.data.length === 0 ? 0 : total.data[0].total;
                  const json = {
                    valorproduto: parseFloat(totalProduto),
                    valortotal:
                      parseFloat(totalProduto) +
                      parseFloat(entidade.valoroutros),
                    importar: 1,
                  };
                  await api.put(`/ajuste/proposta/${id}`, json);
                  // Atualiza o estado
                  let ent = entidade;
                  ent.valortotal = await (parseFloat(totalProduto) +
                    parseFloat(entidade.valoroutros));
                  ent.valorproduto = await parseFloat(totalProduto);
                  // Zera o state e re-atualiza para corrigir o state em 2 "tempos"
                  setEntidade({
                    data: new Date(),
                    cliente_id: null,
                    colaborador_id: null,
                    tipo: 'PEDIDO',
                    precoaprazoavista: 'A VISTA',
                    valorproduto: 0,
                    valoroutros: 0,
                    valortotal: 0,
                    obs: null,
                  });
                  setEntidade(ent);
                  //
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
    e.preventDefault();
  }

  async function listaProdutos(i) {
    const response = await api.get('/propostaitem', {
      params: {
        proposta: i,
      },
    });
    if (response.data.length > 0) await setProdutos(response.data);
  }

  // Renderiza o codigo em tela
  return (
    <Container>
      <Titulo>
        <strong>CADASTRO DE PROPOSTAS</strong>
      </Titulo>
      <Corpo>
        <CorpoForm autoComplete="off" onSubmit={gravar}>
          <CorpoLinha border={true}>
            <strong>DADOS PRINCIPAIS</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={33.333}>
              <Label validado={entidadeValidacao.data}>
                {campoLabel('* Data', 'data')}
              </Label>
              <DateP
                onBlur={() => campoExit('data')}
                validado={entidadeValidacao.data}
                name="data"
                selected={entidade.data}
                onChange={digitarDate}
                locale={pt}
                dateFormat="dd/MM/yyyy"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={66.6666}>
              <Label validado={entidadeValidacao.cliente_id}>
                {campoLabel('* Cliente', 'cliente_id')}
              </Label>
              <Select
                onBlur={() => campoExit('cliente_id')}
                validado={entidadeValidacao.cliente_id}
                name="cliente_id"
                height={44}
                clear={false}
                maxMenuHeight={150}
                placeholder="Digite o cliente"
                onChange={digitarSelect}
                defaultValue={entidade.cliente_id}
                options={clientes}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={33.333}>
              <Label validado={entidadeValidacao.tipo}>
                {campoLabel('* Tipo', 'tipo')}
              </Label>
              <Select
                onBlur={() => campoExit('tipo')}
                validado={entidadeValidacao.tipo}
                name="tipo"
                height={44}
                clear={false}
                maxMenuHeight={100}
                placeholder="Digite o tipo"
                onChange={digitarSelect}
                defaultValue={entidade.tipo}
                options={tipos}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label validado={entidadeValidacao.colaborador_id}>
                {campoLabel('* Vendedor', 'colaborador_id')}
              </Label>
              <Select
                onBlur={() => campoExit('colaborador_id')}
                validado={entidadeValidacao.colaborador_id}
                name="colaborador_id"
                disabled={colaboradorHabilitado}
                height={44}
                clear={false}
                maxMenuHeight={150}
                placeholder="Digite o vendedor"
                onChange={digitarSelect}
                defaultValue={entidade.colaborador_id}
                options={colaboradores}
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label validado={entidadeValidacao.precoaprazoavista}>
                {campoLabel('* Preço a vista / a prazo', 'precoaprazoavista')}
              </Label>
              <Select
                onBlur={() => campoExit('precoaprazoavista')}
                validado={entidadeValidacao.precoaprazoavista}
                disabled={id !== null && id !== undefined ? true : false}
                name="precoaprazoavista"
                height={44}
                clear={false}
                maxMenuHeight={150}
                placeholder="Digite o tipo de preço"
                onChange={digitarSelect}
                defaultValue={entidade.precoaprazoavista}
                options={tipospagamento}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>PRODUTOS</strong>
          </CorpoLinha>
          <CorpoTabela>
            <table>
              <thead>
                <tr>
                  <th>Cod.</th>
                  <th>Produto</th>
                  <th>Quantidade</th>
                  <th>Valor unitário</th>
                  <th>Valor desconto</th>
                  <th>Valor Total</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {produtos !== undefined ? (
                  produtos.map(prod => (
                    <tr key={prod.id}>
                      <CorpoTabelaTd width={'10%'}>
                        {prod.item_id}
                      </CorpoTabelaTd>
                      <CorpoTabelaTd width={'40%'}>
                        {prod.itemdescricao}
                      </CorpoTabelaTd>
                      <CorpoTabelaTd width={'10%'}>
                        {prod.quantidade.replace('.', ',')}
                      </CorpoTabelaTd>
                      <CorpoTabelaTd width={'10%'}>
                        R$ {prod.valorunitario.replace('.', ',')}
                      </CorpoTabelaTd>
                      <CorpoTabelaTd width={'10%'}>
                        R$ {prod.valordesconto.replace('.', ',')}
                      </CorpoTabelaTd>
                      <CorpoTabelaTd width={'10%'}>
                        R$ {prod.valortotal.replace('.', ',')}
                      </CorpoTabelaTd>
                      <CorpoTabelaTd center={true} width={'10%'}>
                        <CorpoTabelaIcone
                          title="Clique para editar o produto"
                          onClick={e => editarProdutos(e, prod.id)}
                        >
                          <FaPencilAlt size={18} color={'#585858'} />
                        </CorpoTabelaIcone>
                        <CorpoTabelaIcone
                          title="Clique para excluir o produto"
                          onClick={e => excluirProdutos(e, prod.id)}
                        >
                          <FaTrash size={18} color={'#d13337'} />
                        </CorpoTabelaIcone>
                      </CorpoTabelaTd>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <CorpoTabelaTd width={'10%'}></CorpoTabelaTd>
                    <CorpoTabelaTd width={'40%'}>
                      Nenhum produto encontrado
                    </CorpoTabelaTd>
                  </tr>
                )}
              </tbody>
            </table>
          </CorpoTabela>
          <CorpoTabelaAdicionar
            block={id === undefined}
            disabled={id === undefined}
            onClick={e => adicionarProdutos(e)}
          >
            <FaPlusCircle size={16} color={'#FFF'} />
            <p>Adicionar</p>
          </CorpoTabelaAdicionar>
          <CorpoLinha border={true}>
            <strong>OBSERVAÇÕES</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={100}>
              <Label>Observações</Label>
              <InputMulti
                importar={true}
                name="obs"
                value={entidade.obs}
                onChange={digitarInput}
                placeholder="Digite a observação"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha border={true}>
            <strong>TOTAIS</strong>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={33.333}>
              <Label>Valor de Produtos</Label>
              <Input
                disabled={true}
                name="valorproduto"
                value={`R$ ${entidade.valorproduto}`}
                //onChange={digitarInput}
                placeholder="0"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label validado={entidadeValidacao.valoroutros}>
                {campoLabel('Outros valores', 'valoroutros')}
              </Label>
              <Input
                onBlur={() => campoExit('valoroutros')}
                validado={entidadeValidacao.valoroutros}
                name="valoroutros"
                value={entidade.valoroutros}
                onChange={digitarInputOutros}
                placeholder="Digite outros valores da proposta"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={33.333}>
              <Label>VALOR TOTAL</Label>
              <Input
                disabled={true}
                name="valortotal"
                value={`R$ ${entidade.valortotal}`}
                //onChange={digitarInput}
                placeholder="0"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <div>
            <CorpoFormButton type="submit">
              <div>
                <FaSave size={18} color={'#fff'} />
              </div>
              Salvar
            </CorpoFormButton>
            <CorpoFormButton tipo="cancel" onClick={cancelar}>
              <div>
                <FaTimes size={18} color={'#029f74'} />
              </div>
              Cancelar
            </CorpoFormButton>
          </div>
        </CorpoForm>
      </Corpo>
      <CorpoFooter />
    </Container>
  );
}
