import React, { useState, useEffect } from 'react';
import history from '../../services/history';
import {
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaDesktop,
  FaFileInvoice,
  FaListAlt,
} from 'react-icons/fa';
import { Scrollbars } from 'react-custom-scrollbars';

import logo from '../../assets/easy2_branco.png';
import logo_min from '../../assets/easy2_branco_marca.png';
import {
  Menu,
  Logo,
  LogoImg,
  Colapse,
  Options,
  OptionsItem,
  OptionsItemMin,
  OptionsSelected,
  OptionsSelectedMin,
  OptionsPath,
} from './styles';

export default function MenuPage() {
  // States
  const [min, setMin] = useState(false);
  const [menuAtivo, setMenuAtivo] = useState('dashboard');
  const [cadastros, setCadastros] = useState(false);

  //
  useEffect(() => {
    let menuItem = '';
    if (
      window.location.pathname === '/cidades' ||
      window.location.pathname === '/cidades-cadastro'
    ) {
      menuItem = 'cidade';
    } else if (
      window.location.pathname === '/clientes' ||
      window.location.pathname === '/clientes-cadastro'
    ) {
      menuItem = 'cliente';
    } else if (
      window.location.pathname === '/produtos' ||
      window.location.pathname === '/produtos-cadastro'
    ) {
      menuItem = 'produto';
    } else if (window.location.pathname === '/dashboard') {
      menuItem = 'dashboard';
    } else if (
      window.location.pathname === '/propostas' ||
      window.location.pathname === 'propostas-cadastro' ||
      window.location.pathname === 'propostas-cadastro-produtos'
    ) {
      menuItem = 'proposta';
    }

    setMenuAtivo(menuItem);
    if (
      menuItem === 'cidade' ||
      menuItem === 'cliente' ||
      menuItem === 'produto'
    ) {
      setCadastros(true);
    }
  }, []);

  // Funcoes de evento de botoes
  async function dashboardClick() {
    history.push('/dashboard');
    setMenuAtivo('dashboard');
  }
  async function cadastroMinClick() {
    setMin(!min);
    setCadastros(true);
  }
  async function cadastroClick() {
    setCadastros(!cadastros);
  }
  async function cidadeClick() {
    history.push('/cidades');
    setMenuAtivo('cidade');
  }
  async function clienteClick() {
    history.push('/clientes');
    setMenuAtivo('cliente');
  }
  async function produtoClick() {
    history.push('/produtos');
    setMenuAtivo('produto');
  }
  async function propostaClick() {
    history.push('/propostas');
    setMenuAtivo('proposta');
  }

  return (
    <Menu min={min}>
      {/** Logo */}
      <Logo>
        <LogoImg min={min} src={min ? logo_min : logo} />
      </Logo>
      {/** Menu */}
      <Options>
        {min ? (
          <Scrollbars hideTracksWhenNotNeeded>
            <OptionsItemMin onClick={dashboardClick}>
              <OptionsSelectedMin
                visible={menuAtivo === 'dashboard' ? true : false}
                size={16}
                color={'#029f74'}
              />
              <FaDesktop size={22} color={'#585858'} />
            </OptionsItemMin>
            <OptionsItemMin onClick={cadastroMinClick}>
              <OptionsSelectedMin
                visible={menuAtivo === 'cidade' && cadastros ? true : false}
                size={16}
                color={'#029f74'}
              />
              <FaListAlt size={22} color={'#585858'} />
            </OptionsItemMin>
            <OptionsItemMin onClick={propostaClick}>
              <OptionsSelectedMin
                visible={menuAtivo === 'proposta' ? true : false}
                size={16}
                color={'#029f74'}
              />
              <FaFileInvoice size={22} color={'#585858'} />
            </OptionsItemMin>
          </Scrollbars>
        ) : (
          <Scrollbars hideTracksWhenNotNeeded>
            {/** Dashboard */}
            <OptionsItem selected={true} onClick={dashboardClick}>
              <OptionsSelected
                visible={menuAtivo === 'dashboard' ? true : false}
                size={16}
                color={'#029f74'}
              />
              <FaDesktop size={20} color={'#585858'} />
              <p>Dashboard</p>
            </OptionsItem>

            {/** Cadastros */}
            <OptionsItem onClick={cadastroClick}>
              <OptionsSelected
                visible={menuAtivo === 'cadastro' ? true : false}
                size={16}
                color={'#029f74'}
              />
              <FaListAlt size={20} color={'#585858'} />
              <p>Cadastros</p>
              {cadastros ? (
                <FaAngleDown size={20} color={'#585858'} />
              ) : (
                <FaAngleRight size={20} color={'#585858'} />
              )}
            </OptionsItem>
            <OptionsItem onClick={cidadeClick} visible={cadastros}>
              <OptionsSelected
                visible={menuAtivo === 'cidade' && cadastros ? true : false}
                size={16}
                color={'#029f74'}
              />
              <OptionsPath />
              <p>Cidades</p>
            </OptionsItem>
            <OptionsItem onClick={clienteClick} visible={cadastros}>
              <OptionsSelected
                visible={menuAtivo === 'cliente' && cadastros ? true : false}
                size={16}
                color={'#029f74'}
              />
              <OptionsPath />
              <p>Clientes</p>
            </OptionsItem>
            <OptionsItem onClick={produtoClick} visible={cadastros}>
              <OptionsSelected
                visible={menuAtivo === 'produto' && cadastros ? true : false}
                size={16}
                color={'#029f74'}
              />
              <OptionsPath margin={true} />
              <p>Produtos</p>
            </OptionsItem>

            {/** Propostas */}
            <OptionsItem onClick={propostaClick}>
              <OptionsSelected
                visible={menuAtivo === 'proposta' ? true : false}
                size={16}
                color={'#029f74'}
              />
              <FaFileInvoice size={20} color={'#585858'} />
              <p>Propostas</p>
            </OptionsItem>
          </Scrollbars>
        )}
      </Options>
      {/** Abre e fecha */}
      <Colapse>
        <button
          onClick={() => {
            setMin(!min);
            setCadastros(false);
          }}
        >
          <div>
            {min ? (
              <FaAngleRight size={24} color={'#585858'} />
            ) : (
              <FaAngleLeft size={24} color={'#585858'} />
            )}
          </div>
        </button>
      </Colapse>
    </Menu>
  );
}
