import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content, Background, Footer } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Background>
      <Wrapper>
        <Content>{children}</Content>
        <Footer>
          <a target="_blank" href="https://easy.3rsistemas.com">
            easy.3rsistemas.com
          </a>
        </Footer>
      </Wrapper>
    </Background>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
