import React from 'react';
import Select from 'react-select';

export default function ReactSelect({
  name,
  label,
  options,
  multiple,
  height,
  validado = true,
  clear,
  defaultValue,
  disabled,
  ...rest
}) {
  const customStyles = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#dedcdc' : '',
      '&:hover': {
        backgroundColor: '#f1f1f1',
      },
    }), // controla a estilizacao da lista
    control: (base, state) => ({
      // controla a estilizacao do combo
      ...base,
      minHeight: '1px',
      height: `${height}px`,
      padding: '0px 0px 0px 0px',
      border: `1px solid ${validado ? '#e6e6e6' : 'red'}`,
      borderRadius: '5px',
      boxShadowColor: '1px solid #e6e6e6',
      boxShadow: 'none',
      backgroundColor: '#FFF',
      '&:hover': {
        borderColor: '#e6e6e6',
      },
    }),
  };

  function getDefaultValue() {
    if (!defaultValue) return null;
    if (!multiple) {
      return options.find(option => option.id === defaultValue);
    }
    return options.filter(option => defaultValue.includes(option.id));
  }

  return (
    <>
      <Select
        name={name}
        isClearable={clear}
        options={options}
        isDisabled={disabled}
        isMulti={multiple}
        value={getDefaultValue()}
        noOptionsMessage={({ inputValue }) => 'Nenhum registro encontrado'}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.title}
        styles={customStyles}
        {...rest}
      />
    </>
  );
}
