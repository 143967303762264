import React, { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as cep from 'cep-promise';

import Select from '../../../components/Select';
import masks from '../../../lib/masks';
import forms from '../../../lib/forms';
import api from '../../../services/api';
import {
  Container,
  Titulo,
  Corpo,
  CorpoForm,
  CorpoFormButton,
  CorpoLinha,
  CorpoLinhaCampo,
  Input,
  Label,
} from '../../../styles/cadastro';
import { Confirmation } from '../../../styles/confirmation';
import history from '../../../services/history';

export default function CidadeCadastro() {
  // States
  const [id, setId] = useState();
  const [entidade, setEntidade] = useState({
    cep: null,
    cidade: null,
    uf: null,
  });
  const [entidadeValidacao, setEntidadeValidacao] = useState({
    cep: true,
    cidade: true,
    uf: true,
  });
  const [ufs, setUFs] = useState([]);
  const [gra, setGra] = useState(true);

  // Form Show
  useEffect(() => {
    // Pega o ID quando edicao
    let i = [];
    const urn = window.location.href.split('?');
    if (urn[1] !== undefined) {
      i = urn[1].split('=');
      setId(i[1]);
    }
    // Faz a consulta dos campos de select
    popularSelects();
    // Consulta dados do ID quando edicao
    if (i[1] !== undefined) {
      async function popularEntidade() {
        let e = await api.get(`cidade/${i[1]}`);
        setEntidade(e.data);
      }
      popularEntidade();
    }
  }, []); // eslint-disable-next-line

  // Popula todos o campos selects
  async function popularSelects() {
    setUFs([
      { title: 'Acre', id: 'AC' },
      { title: 'Alagoas', id: 'AL' },
      { title: 'Amapá', id: 'AP' },
      { title: 'Amazonas', id: 'AM' },
      { title: 'Bahia', id: 'BA' },
      { title: 'Ceará', id: 'CE' },
      { title: 'Distrito Federal', id: 'DF' },
      { title: 'Espírito Santo', id: 'ES' },
      { title: 'Goiás', id: 'GO' },
      { title: 'Maranhão', id: 'MA' },
      { title: 'Mato Grosso', id: 'MT' },
      { title: 'Mato Grosso do Sul', id: 'MS' },
      { title: 'Minas Gerais', id: 'MG' },
      { title: 'Pará', id: 'PA' },
      { title: 'Paraíba', id: 'PB' },
      { title: 'Paraná', id: 'PR' },
      { title: 'Pernambuco', id: 'PE' },
      { title: 'Piauí', id: 'PI' },
      { title: 'Rio de Janeiro', id: 'RJ' },
      { title: 'Rio Grande do Norte', id: 'RN' },
      { title: 'Rio Grande do Sul', id: 'RS' },
      { title: 'Rondônia', id: 'RO' },
      { title: 'Roraima', id: 'RR' },
      { title: 'Santa Catarina', id: 'SC' },
      { title: 'São Paulo', id: 'SP' },
      { title: 'Sergipe', id: 'SE' },
      { title: 'Tocantins', id: 'TO' },
      { title: 'Exterior', id: 'EX' },
    ]);
  }

  // Grava o cadastro e fecha formulario
  async function gravar(e) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();
    if (gra === true) {
      setGra(false);
      // Valida os campos obrigatorios
      const [o, v] = forms.validaFormulario(entidade, entidadeValidacao);
      setEntidadeValidacao(o);
      if (!v) {
        setGra(true);
        return false;
      }
      //
      if (entidade.cep.length !== 9) {
        toast.error('DEFINA O CEP CORRETAMENTE');
        setGra(true);
        return false;
      }
      //
      const a = await api.get('/cidade', {
        params: {
          cep: entidade.cep,
        },
      });
      if (a.data.length === 1) {
        if (`${a.data[0].id}` !== id) {
          toast.error('CEP JA CADASTRADO EM OUTRO REGISTRO');
          setGra(true);
          return false;
        }
      }
      //
      let ent = entidade;
      ent.importar = 1;
      // Limpa campos em branco
      ent = forms.preparaFormulario(ent);
      // Editar
      if (id !== undefined) {
        const response = await api.put(`cidade/${id}`, ent);
        if (response.status === 200) {
          toast.success('CIDADE EDITADA COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO EDITAR A CIDADE');
          setGra(true);
        }
      }
      // Incluir
      else {
        const response = await api.post('cidade', ent);
        if (response.status === 200) {
          toast.success('CIDADE ADICIONADA COM SUCESSO');
        } else {
          toast.error('PROBLEMAS AO ADICIONAR A CIDADE');
          setGra(true);
        }
      }
      history.push('cidades');
    }
  }

  // Cancela o cadastro e fecha o formulario
  async function cancelar(e) {
    // Bloqueia comportamento padrao do formulario
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Confirma o cancelamento do cadastro?</p>
            <div>
              <button
                onClick={async () => {
                  toast.warn('CADASTRO CANCELADO');
                  history.push('cidades');
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
  }

  // Digita o campo no objeto e popula com seu devido valor
  function digitarSelect(e) {
    if (e === null) {
      setEntidade(forms.populaFormulario(entidade, this.name, null));
    } else {
      if (e.id !== undefined) {
        setEntidade(forms.populaFormulario(entidade, this.name, e.id));
      }
    }
  }
  function digitarInput(e) {
    setEntidade(
      forms.populaFormulario(entidade, e.target.name, e.target.value)
    );
  }
  async function digitarInputCep(e) {
    let c = e.target.value;
    if (c.length === 8) {
      cep(c).then(a => {
        let ent = {
          cep: null,
          cidade: null,
          uf: null,
        };
        //if (entidade.cidade === null || entidade.cidade === '') {
        ent.cidade = a.city;
        //}
        //if (entidade.uf === null || entidade.uf === '') {
        ent.uf = a.state;
        //}
        ent.cep = masks.cep(c);
        setEntidade(ent);
      });
    }
    await setEntidade(forms.populaFormulario(entidade, 'cep', masks.cep(c)));
  }

  // Faz a validacao no exit do campo
  function campoExit(campo) {
    setEntidadeValidacao(
      forms.validaFormularioCampo(entidade, entidadeValidacao, campo)
    );
  }

  // Escreve a label conforme parametros de validacao
  function campoLabel(label, field) {
    return `
      ${label}
      ${!entidadeValidacao[field] ? ' (Campo obrigatório)' : ''}
      `;
  }

  // Renderiza o codigo em tela
  return (
    <Container>
      <Titulo>
        <strong>CADASTRO DE CIDADES</strong>
      </Titulo>
      <Corpo>
        <CorpoForm autoComplete="off" onSubmit={gravar}>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label validado={entidadeValidacao.cep}>
                {campoLabel('* CEP', 'cep')}
              </Label>
              <Input
                onBlur={() => campoExit('cep')}
                validado={entidadeValidacao.cep}
                name="cep"
                value={entidade.cep}
                onChange={digitarInputCep}
                maxLength={9}
                placeholder="Digite o CEP"
              />
            </CorpoLinhaCampo>
            <CorpoLinhaCampo size={50}>
              <Label validado={entidadeValidacao.cidade}>
                {campoLabel('* Cidade', 'cidade')}
              </Label>
              <Input
                onBlur={() => campoExit('cidade')}
                validado={entidadeValidacao.cidade}
                name="cidade"
                value={entidade.cidade}
                onChange={digitarInput}
                placeholder="Digite o nome"
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <CorpoLinha>
            <CorpoLinhaCampo size={50}>
              <Label validado={entidadeValidacao.uf}>
                {campoLabel('* UF', 'uf')}
              </Label>
              <Select
                onBlur={() => campoExit('uf')}
                validado={entidadeValidacao.uf}
                name="uf"
                height={44}
                clear={true}
                maxMenuHeight={70}
                placeholder="Digite a UF"
                onChange={digitarSelect}
                defaultValue={entidade.uf}
                options={ufs}
              />
            </CorpoLinhaCampo>
          </CorpoLinha>
          <div>
            <CorpoFormButton type="submit">
              <div>
                <FaSave size={18} color={'#fff'} />
              </div>
              Salvar
            </CorpoFormButton>
            <CorpoFormButton tipo="cancel" onClick={cancelar}>
              <div>
                <FaTimes size={18} color={'#029f74'} />
              </div>
              Cancelar
            </CorpoFormButton>
          </div>
        </CorpoForm>
      </Corpo>
    </Container>
  );
}
