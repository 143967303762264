import pt from 'date-fns/locale/pt';
import { format } from 'date-fns';

class Dates {
  // Formata data para dd/mm/yyyy
  dataVisual(data) {
    if (data === null) return '';
    else if (data === '') return '';
    else if (data === undefined) return '';
    else
      return (
        data.substring(8, 10) +
        '/' +
        data.substring(5, 7) +
        '/' +
        data.substring(0, 4)
      );
  }

  // Formata data para yyyy-mm-dd
  dataSQL(data) {
    if (data === null) return '';
    else if (data === '') return '';
    else if (data === undefined) return '';
    else
      return (
        data.substring(6, 10) +
        '-' +
        data.substring(3, 5) +
        '-' +
        data.substring(0, 2)
      );
  }

  // Pega dia da semana
  diaSemana(data) {
    var semana = ['1', '2', '3', '4', '5', '6', '7'];
    var arr = data.split('/').reverse();
    var dt = new Date(arr[0], arr[1] - 1, arr[2]);
    var dia = dt.getDay();
    return semana[dia];
  }

  // Pega dia da semana por extenso
  diaSemanaExtenso(data) {
    var semana = [
      'Domingo',
      'Segunda-Feira',
      'Terça-Feira',
      'Quarta-Feira',
      'Quinta-Feira',
      'Sexta-Feira',
      'Sábado',
    ];
    var arr = data.split('/').reverse();
    var dt = new Date(arr[0], arr[1] - 1, arr[2]);
    var dia = dt.getDay();
    return semana[dia];
  }

  // Pega ultimo dia do mes
  ultimoDia(data) {
    var arr = data.split('/').reverse();
    var dt = new Date(arr[0], arr[1] - 1, arr[2]);
    return new Date(dt.getFullYear(), dt.getMonth() + 1, 0).getDate();
  }

  // Pega o mes atual
  mesAtual() {
    return format(new Date(), 'MMMM', { locale: pt });
  }

  // Pega o mes atual
  anoAtual() {
    return format(new Date(), 'yyyy', { locale: pt });
  }

  //
  converterMes(mes) {
    switch (mes) {
      case 'janeiro':
        return '01';
      case 'fevereiro':
        return '02';
      case 'março':
        return '03';
      case 'abril':
        return '04';
      case 'maio':
        return '05';
      case 'junho':
        return '06';
      case 'julho':
        return '07';
      case 'agosto':
        return '08';
      case 'setembro':
        return '09';
      case 'outubro':
        return '10';
      case 'novembro':
        return '11';
      case 'dezembro':
        return '12';
      default:
        return '00';
    }
  }

  // Pega ultimo dia do mes
  primeiroDiaMes(mes, ano) {
    return `01/${this.converterMes(mes)}/${ano}`;
  }

  // Pega ultimo dia do mes
  ultimoDiaMes(mes, ano) {
    var d = `01/${this.converterMes(mes)}/${ano}`;
    var arr = d.split('/').reverse();
    var dt = new Date(arr[0], arr[1] - 1, arr[2]);
    return `${new Date(
      dt.getFullYear(),
      dt.getMonth() + 1,
      0
    ).getDate()}/${this.converterMes(mes)}/${ano}`;
  }

  // Lista 5 anos para tras e 5 anos para frente
  listaAnos(ano) {
    var anos = [];
    for (var x = -5; x < 0; x++)
      anos = [...anos, { id: `${ano + x}`, title: `${ano + x}` }];
    for (var y = 0; y <= 5; y++)
      anos = [...anos, { id: `${ano + y}`, title: `${ano + y}` }];
    return anos;
  }
}

export default new Dates();
