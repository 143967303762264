import React from 'react';
import { FaGhost } from 'react-icons/fa';

import { Container } from '../../styles/listagem';
import { Body, BodyLine, Body404, BodyL2 } from './styles';

export default function NaoEncontrada() {
  // Rende da tela
  return (
    <Container>
      <Body>
        <BodyLine>
          <Body404>4</Body404>
          <div>
            <FaGhost size={56} color={'#585858'} />
          </div>
          <Body404>4</Body404>
        </BodyLine>
        <BodyLine>
          <BodyL2>Página não encontrada</BodyL2>
        </BodyLine>
      </Body>
    </Container>
  );
}
