import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  // baseURL: 'http://localhost:3001',
  // baseURL: 'http://157.245.231.176:3001',
  baseURL: 'https://www.3rsistemas-api.com.br/easy-api',
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
