import styled from 'styled-components';

export const Confirmation = styled.div`
  width: 400px;
  height: 170px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.4);

  p {
    font-weight: bold;
    font-size: 18px;
    color: #585858;
    width: 100%;
    height: 116px;
    padding: 20px;
  }

  div {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    button {
      width: 130px;
      margin-right: 10px;
      height: 35px;
      border: 0;
      border-radius: 5px;
      background: #029f74;
      font-weight: bold;
      color: #fff;
      font-size: 14px;
    }
  }
`;
