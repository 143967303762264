import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import pt from 'date-fns/locale/pt';
import { format } from 'date-fns';
import {
  FaPlus,
  FaPrint,
  FaPencilAlt,
  FaBan,
  FaSearch,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';

import { Confirmation } from '../../../styles/confirmation';
import history from '../../../services/history';
import order from '../../../lib/orders';
import relatorio from '../../../lib/relatorios';
import api from '../../../services/api';
import permissions from '../../../lib/permissions';
import Select from '../../../components/Select';
import {
  Container,
  Titulo,
  TituloBotao,
  Corpo,
  CorpoFiltro,
  CorpoTabela,
  TabelaTd,
  TabelaTh,
  CorpoFiltroInput,
  CorpoFiltroBotao,
  CorpoFiltroField,
  CorpoFiltroSelect,
  CorpoPaginacao,
  TabelaTdIcone,
  CorpoFiltroDate,
} from '../../../styles/listagem';

export default function Proposta() {
  // States
  const [filtroCliente, setFiltroCliente] = useState();
  const [filtroVendedor, setFiltroVendedor] = useState();
  const [filtroVendedorId, setFiltroVendedorId] = useState();
  const [filtroPedido, setFiltroPedido] = useState(true);
  const [filtroOrcamento, setFiltroOrcamento] = useState(true);

  const [filtroAndamento, setFiltroAndamento] = useState(true);
  const [filtroCancelado, setFiltroCancelado] = useState(false);
  const [filtroFinalizado, setFiltroFinalizado] = useState(false);
  const [filtroTipoData, setFiltroTipoData] = useState(1);
  const [filtroDataIni, setFiltroDataIni] = useState();
  const [filtroDataFim, setFiltroDataFim] = useState();

  const [selectDatas] = useState([
    {
      id: 1,
      title: 'Abertura',
    },
    {
      id: 2,
      title: 'Cancelamento',
    },
    {
      id: 3,
      title: 'Finalização',
    },
  ]);

  const [entidades, setEntidades] = useState([]);
  const [paginas, setPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [registros, setRegistros] = useState(1);
  const [ordem, setOrdem] = useState({ campo: 'cidade', desc: false });
  const [filtroVendedorHabilitado, setFiltroVendedorHabilitado] = useState(
    false
  );

  // Acha Entidade
  async function carregar(i) {
    const filtroTipo =
      filtroPedido === filtroOrcamento
        ? null
        : filtroPedido
        ? 'PEDIDO'
        : 'ORCAMENTO';

    let status = '';
    if (filtroAndamento === true) status = `'ANDAMENTO',`;
    if (filtroCancelado === true) status = status + `'CANCELADO',`;
    if (filtroFinalizado === true) status = status + `'FINALIZADO',`;
    if (status !== '') status = status.substring(0, status.length - 1);

    const response = await api.get('/proposta', {
      params: {
        todosativoinativo: true,
        cliente: filtroCliente,
        colaborador: filtroVendedor,
        colaboradorid: i > 0 ? i : filtroVendedorId, // primeira vez state demora pra carregar
        tipo: filtroTipo,
        status: status,
        datainicial:
          filtroTipoData === 1 &&
          filtroDataIni !== undefined &&
          filtroDataIni !== null
            ? `${format(filtroDataIni, 'yyyy-MM-dd')}T00:00:00.000Z`
            : null,
        datafinal:
          filtroTipoData === 1 &&
          filtroDataFim !== undefined &&
          filtroDataFim !== null
            ? `${format(filtroDataFim, 'yyyy-MM-dd')}T23:59:00.000Z`
            : null,
        canceladoinicial:
          filtroTipoData === 2 &&
          filtroDataIni !== undefined &&
          filtroDataIni !== null
            ? `${format(filtroDataIni, 'yyyy-MM-dd')}T00:00:00.000Z`
            : null,
        canceladofinal:
          filtroTipoData === 2 &&
          filtroDataFim !== undefined &&
          filtroDataFim !== null
            ? `${format(filtroDataFim, 'yyyy-MM-dd')}T23:59:00.000Z`
            : null,
        finalizadoinicial:
          filtroTipoData === 3 &&
          filtroDataIni !== undefined &&
          filtroDataIni !== null
            ? `${format(filtroDataIni, 'yyyy-MM-dd')}T00:00:00.000Z`
            : null,
        finalizadofinal:
          filtroTipoData === 3 &&
          filtroDataFim !== undefined &&
          filtroDataFim !== null
            ? `${format(filtroDataFim, 'yyyy-MM-dd')}T23:59:00.000Z`
            : null,
      },
    });
    await setOrdem({ campo: 'id', desc: true });
    await setEntidades(response.data);
    await setRegistros(response.data.length);
    await setPaginas(Math.ceil(response.data.length / 10));
    await setPaginaAtual(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
  }

  // Ordena a entidade
  async function ordenar(campo) {
    ordem.campo === campo
      ? setOrdem({ campo, desc: !ordem.desc })
      : setOrdem({ campo, desc: false });
    const o =
      ordem.campo === campo
        ? { campo, desc: !ordem.desc }
        : { campo, desc: false };
    const result =
      o.campo === 'id'
        ? await order.ordenarInt(entidades, o)
        : o.campo === 'valortotal'
        ? await order.ordenarNumeric(entidades, o)
        : await order.ordenarStr(entidades, o);
    setEntidades([]);
    setEntidades(result);
  }
  function ordenarCor(c, upDown) {
    return ordem.campo === c && ordem.desc === upDown ? '#585858' : '#dedcdc';
  }

  // Form show
  useEffect(() => {
    // Filtro de vendedores
    async function filtro() {
      setFiltroCliente();
      setFiltroPedido(true);
      setFiltroOrcamento(true);
      setFiltroDataIni();
      setFiltroDataFim();
      setFiltroTipoData(1);
      setFiltroAndamento(true);
      setFiltroCancelado(false);
      setFiltroFinalizado(false);
      const colaboradorid = await permissions.visualizaGeral();
      if (colaboradorid === null) {
        setFiltroVendedor();
        setFiltroVendedorHabilitado(false);
      } else {
        setFiltroVendedorId(await permissions.colaborador());
        setFiltroVendedor(await permissions.colaboradorNome());
        setFiltroVendedorHabilitado(true);
      }
      carregar(colaboradorid);
    }
    filtro();
    // eslint-disable-next-line
  }, []);

  // Digitacao dos inputs
  function digitarCliente(e) {
    if (e.target.value !== '') {
      setFiltroCliente(e.target.value);
    } else {
      setFiltroCliente();
    }
  }
  function digitarVendedor(e) {
    if (e.target.value !== '') {
      setFiltroVendedor(e.target.value);
    } else {
      setFiltroVendedor();
    }
  }
  function digitarPedido(e) {
    setFiltroPedido(e.target.checked);
  }
  function digitarOrcamento(e) {
    setFiltroOrcamento(e.target.checked);
  }
  function digitarAndamento(e) {
    setFiltroAndamento(e.target.checked);
  }
  function digitarCancelado(e) {
    setFiltroCancelado(e.target.checked);
  }
  function digitarFinalizado(e) {
    setFiltroFinalizado(e.target.checked);
  }
  function digitarDataIni(e) {
    if (e !== '') {
      setFiltroDataIni(e);
    } else {
      setFiltroDataIni();
    }
  }
  function digitarDataFim(e) {
    if (e !== '') {
      setFiltroDataFim(e);
    } else {
      setFiltroDataFim();
    }
  }
  function digitarSelect(e) {
    setFiltroTipoData(e.id);
  }

  // Controle de paginacao
  function digitarPaginaMenos() {
    if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1);
  }
  function digitarPaginaMais() {
    if (paginaAtual !== paginas) setPaginaAtual(paginaAtual + 1);
  }
  function corIconePaginacaoIni() {
    if (paginaAtual <= 1) return '#f8f8f8';
    else return '#029f74';
  }
  function corIconePaginacaoFim() {
    if (paginas === paginaAtual) return '#f8f8f8';
    else return '#029f74';
  }
  function paginacao(p) {
    if (p === 1) return (paginaAtual - 1) * 10;
    else return paginaAtual * 10;
  }

  // Inclusao, alteracao e exclusao
  async function adicionar() {
    await history.push('propostas-cadastro');
  }
  async function editar(e, finalizado, cancelado) {
    finalizado !== null && finalizado !== undefined
      ? toast.error('Proposta finalizada, não é possível alterar')
      : cancelado !== null && cancelado !== undefined
      ? toast.error('Proposta cancelada, não é possível alterar')
      : await history.push(`propostas-cadastro?id=${e}`);
  }
  async function cancelar(id, finalizado, cancelado) {
    finalizado !== null && finalizado !== undefined
      ? toast.error('Proposta finalizada, não é possível cancelar')
      : cancelado !== null && cancelado !== undefined
      ? toast.error('Proposta já cancelada')
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <Confirmation>
                <p>Confirma o cancelamento da proposta?</p>
                <div>
                  <button
                    onClick={async () => {
                      const response = await api.put(`/ajuste/proposta/${id}`, {
                        cancelado: format(new Date(), 'yyyy-MM-dd'),
                        importar: 1,
                      });
                      if (response.status === 200) {
                        toast.success('Proposta cancelada com sucesso!');
                      } else {
                        toast.error(
                          'Problemas ao cancelar proposta, tente novamente!'
                        );
                      }
                      carregar();
                      onClose();
                    }}
                  >
                    Sim
                  </button>
                  <button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Não
                  </button>
                </div>
              </Confirmation>
            );
          },
        });
  }

  function imprimir() {
    const cabecalho =
      '<th>CLIENTE</th><th>VENDEDOR</th><th>DATA</th><th>VALOR TOTAL</th>';
    let conteudo = '';
    entidades.map(
      e =>
        (conteudo =
          conteudo +
          `<tr key=${e.id}> ` +
          `<td>${e.cliente.razaosocial}</td>  ` +
          `<td>${e.colaborador.nome}</td> ` +
          `<td>${e.data}</td> ` +
          `<td>${e.valortotal}</td> ` +
          `</tr>`)
    );
    relatorio.relatorio('RELAÇÃO DE PROPOSTAS', cabecalho, conteudo);
  }

  // Render da tela
  return (
    <Container>
      <Titulo>
        <strong>LISTAGEM DE PROPOSTAS</strong>
        <div>
          <TituloBotao destaque={true} onClick={adicionar}>
            <div>
              <FaPlus size={18} color={'#fff'} />
            </div>
            Adicionar
          </TituloBotao>
          <TituloBotao onClick={imprimir}>
            <div>
              <FaPrint size={18} color={'#029f74'} />
            </div>
            Imprimir
          </TituloBotao>
        </div>
      </Titulo>
      <Corpo>
        <CorpoFiltro>
          <CorpoFiltroInput
            onInput={digitarCliente}
            size={30.5}
            placeholder="Filtre pelo cliente"
          />
          <CorpoFiltroInput
            onInput={digitarVendedor}
            defaultValue={filtroVendedor}
            disabled={filtroVendedorHabilitado}
            size={30.5}
            placeholder="Filtre pelo vendedor"
          />
          <CorpoFiltroField size={19.5}>
            <input
              type="checkbox"
              checked={filtroPedido}
              onChange={digitarPedido}
              defaultValue={filtroPedido}
            />
            <strong>PEDIDOS</strong>
          </CorpoFiltroField>
          <CorpoFiltroField size={19.5} last={true}>
            <input
              type="checkbox"
              checked={filtroOrcamento}
              onChange={digitarOrcamento}
              defaultValue={filtroOrcamento}
            />
            <strong>ORÇAMENTOS</strong>
          </CorpoFiltroField>
        </CorpoFiltro>
        <CorpoFiltro second={true}>
          <CorpoFiltroSelect>
            <Select
              name="filtroTipoData"
              height={35}
              clear={false}
              maxMenuHeight={150}
              placeholder="Digite o tipo de data"
              onChange={digitarSelect}
              defaultValue={filtroTipoData}
              options={selectDatas}
            />
          </CorpoFiltroSelect>
          <CorpoFiltroField date={true} size={16.666}>
            <CorpoFiltroDate
              onChange={digitarDataIni}
              selected={filtroDataIni}
              dateFormat="dd/MM/yyyy"
              locale={pt}
              size={100}
              placeholderText="Data inicial"
            />
          </CorpoFiltroField>
          <CorpoFiltroField date={true} size={16.666}>
            <CorpoFiltroDate
              onChange={digitarDataFim}
              selected={filtroDataFim}
              dateFormat="dd/MM/yyyy"
              locale={pt}
              size={100}
              placeholderText="Data final"
            />
          </CorpoFiltroField>
          <CorpoFiltroField size={19.5}>
            <input
              type="checkbox"
              checked={filtroAndamento}
              onChange={digitarAndamento}
              defaultValue={filtroAndamento}
            />
            <strong>ANDAMENTO</strong>
          </CorpoFiltroField>
          <CorpoFiltroField size={19.5}>
            <input
              type="checkbox"
              checked={filtroCancelado}
              onChange={digitarCancelado}
              defaultValue={filtroCancelado}
            />
            <strong>CANCELADO</strong>
          </CorpoFiltroField>
          <CorpoFiltroField size={19.5}>
            <input
              type="checkbox"
              checked={filtroFinalizado}
              onChange={digitarFinalizado}
              defaultValue={filtroFinalizado}
            />
            <strong>FINALIZADO</strong>
          </CorpoFiltroField>
          <CorpoFiltroBotao onClick={carregar}>
            <div>
              <FaSearch size={18} color={'#fff'} />
            </div>
          </CorpoFiltroBotao>
        </CorpoFiltro>
        <CorpoTabela id="tabela">
          <table>
            <thead>
              <tr>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('id');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('id', false)} />
                    <FaArrowDown size={14} color={ordenarCor('id', true)} />
                  </button>
                  Cod.
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('cliente.razaosocial');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('cliente.razaosocial', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('cliente.razaosocial', true)}
                    />
                  </button>
                  Cliente
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('colaborador.nome');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('colaborador.nome', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('colaborador.nome', true)}
                    />
                  </button>
                  Vendedor
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('data');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('data', false)} />
                    <FaArrowDown size={14} color={ordenarCor('data', true)} />
                  </button>
                  Data
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('tipo');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('tipo', false)} />
                    <FaArrowDown size={14} color={ordenarCor('tipo', true)} />
                  </button>
                  Tipo
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('valortotal');
                    }}
                  >
                    <FaArrowUp
                      size={14}
                      color={ordenarCor('valortotal', false)}
                    />
                    <FaArrowDown
                      size={14}
                      color={ordenarCor('valortotal', true)}
                    />
                  </button>
                  Total
                </TabelaTh>
                <TabelaTh last={true} center={true}>
                  Ações
                </TabelaTh>
              </tr>
            </thead>
            <tbody>
              {entidades.slice(paginacao(1), paginacao(2)).map(e => (
                <tr key={e.id}>
                  <TabelaTd
                    finalizado={e.finalizado === null ? false : true}
                    cancelado={e.cancelado === null ? false : true}
                    width={'10%'}
                    center={true}
                  >
                    {e.id}
                  </TabelaTd>
                  <TabelaTd
                    finalizado={e.finalizado === null ? false : true}
                    cancelado={e.cancelado === null ? false : true}
                    width={'30%'}
                  >
                    {e.cliente.razaosocial}
                  </TabelaTd>
                  <TabelaTd
                    finalizado={e.finalizado === null ? false : true}
                    cancelado={e.cancelado === null ? false : true}
                    width={'20%'}
                  >
                    {e.colaborador.nome}
                  </TabelaTd>
                  <TabelaTd
                    finalizado={e.finalizado === null ? false : true}
                    cancelado={e.cancelado === null ? false : true}
                    width={'10%'}
                  >
                    {e.data}
                  </TabelaTd>
                  <TabelaTd
                    finalizado={e.finalizado === null ? false : true}
                    cancelado={e.cancelado === null ? false : true}
                    width={'10%'}
                  >
                    {e.tipo === 'ORCAMENTO' ? 'ORÇAMENTO' : e.tipo}
                  </TabelaTd>
                  <TabelaTd
                    finalizado={e.finalizado === null ? false : true}
                    cancelado={e.cancelado === null ? false : true}
                    width={'10%'}
                  >
                    R$ {e.valortotal.replace('.', ',')}
                  </TabelaTd>
                  <TabelaTd
                    finalizado={e.finalizado === null ? false : true}
                    cancelado={e.cancelado === null ? false : true}
                    width={'10%'}
                    center={true}
                  >
                    <TabelaTdIcone
                      title="Clique para editar a proposta"
                      onClick={() => {
                        editar(e.id, e.finalizado, e.cancelado);
                      }}
                    >
                      <FaPencilAlt size={18} color={'#585858'} />
                    </TabelaTdIcone>
                    <TabelaTdIcone
                      title="Clique para cancelar a proposta"
                      onClick={() => {
                        cancelar(e.id, e.finalizado, e.cancelado);
                      }}
                    >
                      <FaBan size={18} color={'#d13337'} />
                    </TabelaTdIcone>
                  </TabelaTd>
                </tr>
              ))}
            </tbody>
          </table>
        </CorpoTabela>
        <CorpoPaginacao>
          <span>{registros} registro(s) encontrado(s)</span>
          <div>
            <button onClick={digitarPaginaMenos}>
              <FaChevronCircleLeft size={26} color={corIconePaginacaoIni()} />
            </button>
            <strong>
              {paginaAtual}/{paginas}
            </strong>
            <button onClick={digitarPaginaMais}>
              <FaChevronCircleRight size={26} color={corIconePaginacaoFim()} />
            </button>
          </div>
        </CorpoPaginacao>
      </Corpo>
    </Container>
  );
}
