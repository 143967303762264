import styled, { css, keyframes } from 'styled-components';

import { FaAngleRight } from 'react-icons/fa';

const fade = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const open = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 50px;
  }
`;
const close = keyframes`
  0% {
    height: 50px;
  }
  100% {
    height: 0;
  }
`;

//
export const Menu = styled.div`
  height: 100%;
  width: ${props => (props.min ? '90px' : '280px')};
  min-width: ${props => (props.min ? '90px' : '280px')};
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  transition-duration: 0.2s;
  box-shadow: 4px 5px 15px 1px rgba(0, 0, 0, 0.03);
`;

export const Logo = styled.div`
  width: 100%;
  min-height: 65px;
  height: 65px;
  background: #029f74;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
`;

export const LogoImg = styled.img`
  animation: ${fade} 1s;
  width: ${props => (props.min ? '45%' : '50%')};
  height: ${props => (props.min ? '63%' : '67%')};
`;

export const Options = styled.div`
  width: 100%;
  height: 82vh;
  padding: 15px 2px 15px 2px;
  flex-direction: column;
`;

export const OptionsItem = styled.button`
  display: flex;
  visibility: ${props => (props.visible === false ? 'hidden' : 'visible')};
  animation: ${props =>
      props.visible === false
        ? css`
            ${close}
          `
        : css`
            ${open}
          `}
    0.3s;
  width: 100%;
  height: ${props => (props.visible === false ? '0' : '50px')};
  margin-top: ${props => (props.visible === false ? '0' : '5px')};
  padding: 0 10% 0 10%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  background: #fff;
  &:hover {
    p {
      color: #029f74;
    }
    path {
      color: #029f74;
    }
  }
  p {
    background: transparent;
    transition: 0.3s;
    color: #585858;
    font-weight: bold;
    font-size: 15px;
    width: 60%;
  }
`;

export const OptionsSelected = styled(FaAngleRight)`
  margin-right: 10px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const OptionsPath = styled.path`
  margin-right: ${props => (props.margin === true ? '3px' : '0')};
  width: 15px;
  height: 15px;
`;

export const OptionsItemMin = styled.button`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0 10% 0 0;
  align-items: center;
  justify-content: center;
  border: 0;
  background: #fff;
  &:hover {
    p {
      color: #029f74;
    }
    path {
      color: #029f74;
    }
  }
`;

export const OptionsSelectedMin = styled(FaAngleRight)`
  margin-right: 2px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const Colapse = styled.div`
  width: 100%;
  height: 9vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f1f1f1;
    }
  }
`;
