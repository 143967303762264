import React, { useState, useEffect } from 'react';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { FaSearch } from 'react-icons/fa';

import api from '../../services/api';
import date from '../../lib/dates';
import history from '../../services/history';
import Select from '../../components/Select';
import permissions from '../../lib/permissions';
import {
  Container,
  Colunas,
  Grafico,
  GraficoL1,
  GraficoTitulo,
  GraficoConsulta,
  GraficoConsultaBotao,
  GraficoCorpo,
  GraficoCard,
} from './styles';

export default function Dashboard() {
  //
  const [meses] = useState([
    { id: 'janeiro', title: 'Janeiro' },
    { id: 'fevereiro', title: 'Fevereiro' },
    { id: 'março', title: 'Março' },
    { id: 'abril', title: 'Abril' },
    { id: 'maio', title: 'Maio' },
    { id: 'junho', title: 'Junho' },
    { id: 'julho', title: 'Julho' },
    { id: 'agosto', title: 'Agosto' },
    { id: 'setembro', title: 'Setembro' },
    { id: 'outubro', title: 'Outubro' },
    { id: 'novembro', title: 'Novembro' },
    { id: 'dezembro', title: 'Dezembro' },
  ]);
  const [anos, setAnos] = useState([]);

  const [habilitaFinanceiro, setHabilitaFinanceiro] = useState(false);

  const [ctarec, setCtaRec] = useState({});
  const [ctapag, setCtaPag] = useState({});
  const [vendas, setVendas] = useState({});
  const [propostasQtd, setPropostasQtd] = useState(0);
  const [propostasQtdFin, setPropostasQtdFin] = useState(0);
  const [propostasQtdCan, setPropostasQtdCan] = useState(0);
  const [propostasDia, setPropostasDia] = useState({});
  const [propostasDiaFin, setPropostasDiaFin] = useState({});
  const [propostasDiaCan, setPropostasDiaCan] = useState({});

  const [mesctarec, setMesCtaRec] = useState('Janeiro');
  const [anoctarec, setAnoCtaRec] = useState('0000');
  const [mesctapag, setMesCtaPag] = useState('Janeiro');
  const [anoctapag, setAnoCtaPag] = useState('0000');
  const [mesvendas, setMesVendas] = useState('Janeiro');
  const [anovendas, setAnoVendas] = useState('0000');
  const [mespropostaQtd, setMesPropostaQtd] = useState('Janeiro');
  const [anopropostaQtd, setAnoPropostaQtd] = useState('0000');
  const [mespropostaDia, setMesPropostaDia] = useState('Janeiro');
  const [anopropostaDia, setAnoPropostaDia] = useState('0000');

  // Form show
  useEffect(() => {
    async function populaGraficos() {
      // Seta anos no state
      setAnos(date.listaAnos(parseInt(format(new Date(), 'yyyy'), 10)));
      // Pega mes atual
      setMesCtaRec(date.mesAtual());
      setMesCtaPag(date.mesAtual());
      setMesVendas(date.mesAtual());
      setMesPropostaDia(date.mesAtual());
      setMesPropostaQtd(date.mesAtual());
      setAnoCtaRec(date.anoAtual());
      setAnoCtaPag(date.anoAtual());
      setAnoVendas(date.anoAtual());
      setAnoPropostaDia(date.anoAtual());
      setAnoPropostaQtd(date.anoAtual());
      //
      const colaboradorid = await permissions.visualizaGeral();
      colaboradorid === null
        ? setHabilitaFinanceiro(true)
        : setHabilitaFinanceiro(false);
      // Executa as funcoes que populam os graficos
      populaCtaRec(
        `${date.dataSQL(
          date.primeiroDiaMes(date.mesAtual(), date.anoAtual())
        )}T00:00:00.000Z`,
        `${date.dataSQL(
          date.ultimoDiaMes(date.mesAtual(), date.anoAtual())
        )}T23:59:59.000Z`
      );
      populaCtaPag(
        `${date.dataSQL(
          date.primeiroDiaMes(date.mesAtual(), date.anoAtual())
        )}T00:00:00.000Z`,
        `${date.dataSQL(
          date.ultimoDiaMes(date.mesAtual(), date.anoAtual())
        )}T23:59:59.000Z`
      );
      populaVendas(
        `${date.dataSQL(
          date.primeiroDiaMes(date.mesAtual(), date.anoAtual())
        )}T00:00:00.000Z`,
        `${date.dataSQL(
          date.ultimoDiaMes(date.mesAtual(), date.anoAtual())
        )}T23:59:59.000Z`
      );
      populaPropostaDia(
        `${date.dataSQL(
          date.primeiroDiaMes(date.mesAtual(), date.anoAtual())
        )}T00:00:00.000Z`,
        `${date.dataSQL(
          date.ultimoDiaMes(date.mesAtual(), date.anoAtual())
        )}T23:59:59.000Z`
      );
      populaPropostaQtd(
        `${date.dataSQL(
          date.primeiroDiaMes(date.mesAtual(), date.anoAtual())
        )}T00:00:00.000Z`,
        `${date.dataSQL(
          date.ultimoDiaMes(date.mesAtual(), date.anoAtual())
        )}T23:59:59.000Z`
      );
    }
    populaGraficos();
  }, []);

  // Funcao que monta o grafico de contas a receber
  async function populaCtaRec(ini, fim) {
    // consulta na api
    const response = await api.get('/relatorios/receber/atrasadoabertopago', {
      params: { ini, fim },
    });

    // seta no state
    setCtaRec({
      labels: ['Atrasado', 'Aberto', 'Pago'],
      datasets: [
        {
          data: [
            response.data.atrasado,
            response.data.aberto,
            response.data.pago,
          ],
          backgroundColor: ['#F78181', '#BDBDBD', '#89F1B6'],
        },
      ],
    });
  }

  // Funcao que monta o grafico de contas a pagar
  async function populaCtaPag(ini, fim) {
    // consulta na api
    const response = await api.get('/relatorios/pagar/atrasadoabertopago', {
      params: { ini, fim },
    });

    // seta no state
    setCtaPag({
      labels: ['Atrasado', 'Aberto', 'Pago'],
      datasets: [
        {
          data: [
            response.data.atrasado,
            response.data.aberto,
            response.data.pago,
          ],
          backgroundColor: ['#F78181', '#BDBDBD', '#89F1B6'],
        },
      ],
    });
  }

  // Funcao que monta o grafico de vendas
  async function populaVendas(ini, fim) {
    // consulta na api
    const response = await api.get('/relatorios/venda/totaldiario', {
      params: { ini, fim },
    });

    // Prepara os dados para serem populados
    let label = [];
    let valor = [];
    response.data.forEach(d => {
      label.push(format(new Date(d.dia), 'dd'));
      valor.push(
        (
          parseFloat(d.totalVendas > 0 ? d.totalVendas : 0) +
          parseFloat(d.totalNotas > 0 ? d.totalNotas : 0)
        ).toFixed(2)
      );
    });

    // seta no state
    setVendas({
      labels: label,
      datasets: [
        {
          label: 'Vendas diárias',
          data: valor,
        },
      ],
    });
  }

  // Funcao que monta os cards de propostas
  async function populaPropostaQtd(ini, fim) {
    // pegar colab
    let colaboradorid = await permissions.visualizaGeral();
    if (colaboradorid === null) colaboradorid = undefined; // pra funcionar na api

    // consulta na api
    const propostas = await api.get('/relatorios/proposta/qtdmes', {
      params: { ini, fim, colaboradorid },
    });
    setPropostasQtd(propostas.data[0].total);

    const propostasFin = await api.get('/relatorios/proposta/qtdmes', {
      params: { ini, fim, finalizado: true, colaboradorid },
    });
    setPropostasQtdFin(propostasFin.data[0].total);

    const propostasCan = await api.get('/relatorios/proposta/qtdmes', {
      params: { ini, fim, cancelado: true, colaboradorid },
    });
    setPropostasQtdCan(propostasCan.data[0].total);
  }

  // Funcao que monta o grafico de propostas
  async function populaPropostaDia(ini, fim) {
    // pegar colab
    let colaboradorid = await permissions.visualizaGeral();
    if (colaboradorid === null) colaboradorid = undefined; // pra funcionar na api

    // consulta api
    const response = await api.get('/relatorios/proposta/totaldiario', {
      params: { ini, fim, colaboradorid },
    });
    // Prepara os dados para serem populados
    let label = [];
    let valor = [];
    response.data.forEach(d => {
      label.push(format(new Date(d.dia), 'dd'));
      valor.push(parseFloat(d.total > 0 ? d.total : 0).toFixed(2));
    });

    // seta no state
    const p1 = {
      labels: label,
      datasets: [
        {
          label: 'Propostas diárias (Dia x Valor)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#BDBDBD',
          borderColor: '#BDBDBD',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#BDBDBD',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 6,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: '#BDBDBD',
          pointHoverBorderColor: '#BDBDBD',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: valor,
        },
      ],
    };

    // FINALIZADAS
    const response2 = await api.get('/relatorios/proposta/totaldiario', {
      params: { ini, fim, finalizado: true, colaboradorid },
    });
    // Prepara os dados para serem populados
    label = [];
    valor = [];
    response2.data.forEach(d => {
      label.push(format(new Date(d.dia), 'dd'));
      valor.push(parseFloat(d.total > 0 ? d.total : 0).toFixed(2));
    });

    // seta no state
    const p2 = {
      labels: label,
      datasets: [
        {
          label: 'Propostas (Dia x Valor)',
          type: 'line',
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#',
          borderColor: '#89F1B6',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#89F1B6',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 6,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: '#89F1B6',
          pointHoverBorderColor: '#89F1B6',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: valor,
        },
      ],
    };

    // CANCELADAS
    const response3 = await api.get('/relatorios/proposta/totaldiario', {
      params: { ini, fim, cancelado: true, colaboradorid },
    });
    // Prepara os dados para serem populados
    label = [];
    valor = [];
    response3.data.forEach(d => {
      label.push(format(new Date(d.dia), 'dd'));
      valor.push(parseFloat(d.total > 0 ? d.total : 0).toFixed(2));
    });
    // seta no state
    const p3 = {
      labels: label,
      datasets: [
        {
          label: 'Propostas canceladas (Dia x Valor)',
          type: 'line',
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#F78181',
          borderColor: '#F78181',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#F78181',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 6,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: '#F78181',
          pointHoverBorderColor: '#F78181',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: valor,
        },
      ],
    };

    setPropostasDia(p1);
    setPropostasDiaFin(p2);
    setPropostasDiaCan(p3);
  }

  // Funcoes dos inputs de mes e ano
  function digitarMesCtaRec(e) {
    setMesCtaRec(e.id);
    populaCtaRec(
      `${date.dataSQL(date.primeiroDiaMes(e.id, anoctarec))}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(e.id, anoctarec))}T23:59:59.000Z`
    );
  }
  function digitarAnoCtaRec(e) {
    setAnoCtaRec(e.id);
    populaCtaRec(
      `${date.dataSQL(date.primeiroDiaMes(mesctarec, e.id))}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(mesctarec, e.id))}T23:59:59.000Z`
    );
  }
  function digitarMesCtaPag(e) {
    setMesCtaPag(e.id);
    populaCtaPag(
      `${date.dataSQL(date.primeiroDiaMes(e.id, anoctapag))}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(e.id, anoctapag))}T23:59:59.000Z`
    );
  }
  function digitarAnoCtaPag(e) {
    setAnoCtaPag(e.id);
    populaCtaPag(
      `${date.dataSQL(date.primeiroDiaMes(mesctapag, e.id))}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(mesctapag, e.id))}T23:59:59.000Z`
    );
  }
  function digitarMesVendas(e) {
    setMesVendas(e.id);
    populaVendas(
      `${date.dataSQL(date.primeiroDiaMes(e.id, anovendas))}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(e.id, anovendas))}T23:59:59.000Z`
    );
  }
  function digitarAnoVendas(e) {
    setAnoVendas(e.id);
    populaVendas(
      `${date.dataSQL(date.primeiroDiaMes(mesvendas, e.id))}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(mesvendas, e.id))}T23:59:59.000Z`
    );
  }
  function digitarMesPropostaQtd(e) {
    setMesPropostaQtd(e.id);
    populaPropostaQtd(
      `${date.dataSQL(
        date.primeiroDiaMes(e.id, anopropostaQtd)
      )}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(e.id, anopropostaQtd))}T23:59:59.000Z`
    );
  }
  function digitarAnoPropostaQtd(e) {
    setAnoPropostaQtd(e.id);
    populaPropostaQtd(
      `${date.dataSQL(
        date.primeiroDiaMes(mespropostaQtd, e.id)
      )}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(mespropostaQtd, e.id))}T23:59:59.000Z`
    );
  }
  function digitarMesPropostaDia(e) {
    setMesPropostaDia(e.id);
    populaPropostaDia(
      `${date.dataSQL(
        date.primeiroDiaMes(e.id, anopropostaDia)
      )}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(e.id, anopropostaDia))}T23:59:59.000Z`
    );
  }
  function digitarAnoPropostaDia(e) {
    setAnoPropostaDia(e.id);
    populaPropostaDia(
      `${date.dataSQL(
        date.primeiroDiaMes(mespropostaDia, e.id)
      )}T00:00:00.000Z`,
      `${date.dataSQL(date.ultimoDiaMes(mespropostaDia, e.id))}T23:59:59.000Z`
    );
  }

  async function consultarContasReceber() {
    await history.push(`contas-receber?mes=${mesctarec}&ano=${anoctarec}`);
  }

  async function consultarContasPagar() {
    await history.push(`contas-pagar?mes=${mesctapag}&ano=${anoctapag}`);
  }

  async function consultarVendas() {
    await history.push(`vendas?mes=${mesvendas}&ano=${anovendas}`);
  }

  //
  return (
    <Container>
      <Colunas left>
        <Grafico bottom size={'170px'}>
          <GraficoTitulo>
            <strong>Quantitativo de propostas de</strong>
            <Select
              name="mes"
              height={44}
              maxMenuHeight={400}
              clear={false}
              placeholder="Selecione o mês"
              onChange={digitarMesPropostaQtd}
              defaultValue={mespropostaQtd}
              options={meses}
            />
            <Select
              name="ano"
              height={44}
              maxMenuHeight={400}
              clear={false}
              placeholder="Selecione o ano"
              onChange={digitarAnoPropostaQtd}
              defaultValue={anopropostaQtd}
              options={anos}
            />
          </GraficoTitulo>
          <GraficoCorpo>
            <GraficoCard>
              <strong>Propostas digitadas</strong>
              <p>{propostasQtd}</p>
            </GraficoCard>
            <GraficoCard>
              <strong>Propostas finalizadas</strong>
              <p>{propostasQtdFin}</p>
            </GraficoCard>
            <GraficoCard>
              <strong>Propostas canceladas</strong>
              <p>{propostasQtdCan}</p>
            </GraficoCard>
          </GraficoCorpo>
        </Grafico>
        <Grafico size={'780px'}>
          <GraficoTitulo>
            <strong>Propostas de</strong>
            <Select
              name="mes"
              height={44}
              maxMenuHeight={400}
              clear={false}
              placeholder="Selecione o mês"
              onChange={digitarMesPropostaDia}
              defaultValue={mespropostaDia}
              options={meses}
            />
            <Select
              name="ano"
              height={44}
              maxMenuHeight={400}
              clear={false}
              placeholder="Selecione o ano"
              onChange={digitarAnoPropostaDia}
              defaultValue={anopropostaDia}
              options={anos}
            />
          </GraficoTitulo>
          <Line
            width={500}
            height={200}
            options={{
              responsive: false,
              maintainAspectRatio: false,
            }}
            data={propostasDia}
          />
          <Line
            width={500}
            height={200}
            options={{
              responsive: false,
              maintainAspectRatio: false,
            }}
            data={propostasDiaFin}
          />
          <Line
            width={500}
            height={200}
            options={{
              responsive: false,
              maintainAspectRatio: false,
            }}
            data={propostasDiaCan}
          />
        </Grafico>
      </Colunas>
      {habilitaFinanceiro === true ? (
        <Colunas>
          <Grafico bottom size={'350px'}>
            <GraficoL1>
              <GraficoTitulo>
                <strong>Contas a receber de</strong>
                <Select
                  name="mes"
                  height={44}
                  maxMenuHeight={400}
                  clear={false}
                  placeholder="Selecione o mês"
                  onChange={digitarMesCtaRec}
                  defaultValue={mesctarec}
                  options={meses}
                />
                <Select
                  name="ano"
                  height={44}
                  maxMenuHeight={400}
                  clear={false}
                  placeholder="Selecione o ano"
                  onChange={digitarAnoCtaRec}
                  defaultValue={anoctarec}
                  options={anos}
                />
              </GraficoTitulo>
              <GraficoConsulta>
                <GraficoConsultaBotao onClick={consultarContasReceber}>
                  <FaSearch size={18} color={'#fff'} />
                </GraficoConsultaBotao>
              </GraficoConsulta>
            </GraficoL1>
            <Pie
              width={280}
              height={200}
              options={{
                legend: {
                  position: 'right',
                },
                responsive: false,
                maintainAspectRatio: false,
              }}
              data={ctarec}
            />
          </Grafico>
          <Grafico bottom size={'350px'}>
            <GraficoL1>
              <GraficoTitulo>
                <strong>Contas a pagar de</strong>
                <Select
                  name="mes"
                  height={44}
                  maxMenuHeight={400}
                  clear={false}
                  placeholder="Selecione o mês"
                  onChange={digitarMesCtaPag}
                  defaultValue={mesctapag}
                  options={meses}
                />
                <Select
                  name="ano"
                  height={44}
                  maxMenuHeight={400}
                  clear={false}
                  placeholder="Selecione o ano"
                  onChange={digitarAnoCtaPag}
                  defaultValue={anoctapag}
                  options={anos}
                />
              </GraficoTitulo>
              <GraficoConsulta>
                <GraficoConsultaBotao onClick={consultarContasPagar}>
                  <FaSearch size={18} color={'#fff'} />
                </GraficoConsultaBotao>
              </GraficoConsulta>
            </GraficoL1>
            <Pie
              width={280}
              height={200}
              options={{
                legend: {
                  position: 'right',
                },
                responsive: false,
                maintainAspectRatio: false,
              }}
              data={ctapag}
            />
          </Grafico>
          <Grafico size={'350px'}>
            <GraficoL1>
              <GraficoTitulo>
                <strong>Vendas de </strong>
                <Select
                  name="mes"
                  height={44}
                  maxMenuHeight={400}
                  clear={false}
                  placeholder="Selecione o mês"
                  onChange={digitarMesVendas}
                  defaultValue={mesvendas}
                  options={meses}
                />
                <Select
                  name="ano"
                  height={44}
                  maxMenuHeight={400}
                  clear={false}
                  placeholder="Selecione o ano"
                  onChange={digitarAnoVendas}
                  defaultValue={anovendas}
                  options={anos}
                />
              </GraficoTitulo>
              <GraficoConsulta>
                <GraficoConsultaBotao onClick={consultarVendas}>
                  <FaSearch size={18} color={'#fff'} />
                </GraficoConsultaBotao>
              </GraficoConsulta>
            </GraficoL1>
            <Bar
              width={500}
              height={200}
              options={{
                responsive: false,
                maintainAspectRatio: false,
              }}
              data={vendas}
            />
          </Grafico>
        </Colunas>
      ) : (
        <div></div>
      )}
    </Container>
  );
}
