import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import apiauth from '../services/apiAuth';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  let signed = localStorage.getItem('@3reasy:JWT_TOKEN');

  async function verificaToken() {
    try {
      await apiauth.get(`/sessao/${localStorage.getItem('@3reasy:USER_ID')}`);
    } catch (err) {
      localStorage.removeItem('@3reasy:JWT_TOKEN');
      localStorage.removeItem('@3reasy:USER_ID');
      localStorage.removeItem('@3reasy:USER_NAME');
      localStorage.removeItem('@3reasy:USER_VG');
      return <Redirect to="/" />;
    }
  }
  verificaToken();

  // Se usuario nao esta logado e quer acessar rota privada, vai para login
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  // Se usuario esta logado e rota não é privada manda pro dashboard
  if (signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  // Monta o Layout de acordo com estar logado ou nao
  const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
