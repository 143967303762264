import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  FaPlus,
  FaPrint,
  FaPencilAlt,
  FaTrash,
  FaSearch,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';

import { Confirmation } from '../../../styles/confirmation';
import history from '../../../services/history';
import order from '../../../lib/orders';
import relatorio from '../../../lib/relatorios';
import api from '../../../services/api';
import masks from '../../../lib/masks';
import {
  Container,
  Titulo,
  TituloBotao,
  Corpo,
  CorpoFiltro,
  CorpoTabela,
  TabelaTd,
  TabelaTdCenter,
  TabelaTh,
  CorpoFiltroInput,
  CorpoFiltroBotao,
  CorpoPaginacao,
  TabelaTdIcone,
} from '../../../styles/listagem';

export default function Cidade() {
  // States
  const [filtroCep, setFiltroCep] = useState();
  const [filtroCidade, setFiltroCidade] = useState();
  const [filtroUf, setFiltroUf] = useState();

  const [entidades, setEntidades] = useState([]);
  const [paginas, setPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [registros, setRegistros] = useState(1);
  const [ordem, setOrdem] = useState({ campo: 'cidade', desc: false });

  // Acha Entidade
  async function carregar() {
    const response = await api.get('/cidade', {
      params: { cep: filtroCep, cidade: filtroCidade, uf: filtroUf },
    });
    await setOrdem({ campo: 'cidade', desc: false });
    await setEntidades(response.data);
    await setRegistros(response.data.length);
    // await setPaginaAtual(1);
    await setPaginas(Math.ceil(response.data.length / 10));
    await setPaginaAtual(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
  }

  // Ordena a entidade
  async function ordenar(campo) {
    ordem.campo === campo
      ? setOrdem({ campo, desc: !ordem.desc })
      : setOrdem({ campo, desc: false });
    const o =
      ordem.campo === campo
        ? { campo, desc: !ordem.desc }
        : { campo, desc: false };
    const result =
      o.campo === 'id'
        ? await order.ordenarInt(entidades, o)
        : await order.ordenarStr(entidades, o);
    setEntidades([]);
    setEntidades(result);
  }
  function ordenarCor(c, upDown) {
    return ordem.campo === c && ordem.desc === upDown ? '#585858' : '#dedcdc';
  }

  // Form show
  useEffect(() => {
    setFiltroCep();
    setFiltroCidade();
    setFiltroUf();
    carregar();
    // eslint-disable-next-line
  }, []);

  // Digitacao dos inputs
  function digitarCep(e) {
    if (e.target.value !== '') {
      setFiltroCep(masks.cep(e.target.value));
    } else {
      setFiltroCep();
    }
  }
  function digitarCidade(e) {
    if (e.target.value !== '') {
      setFiltroCidade(e.target.value);
    } else {
      setFiltroCidade();
    }
  }
  function digitarUf(e) {
    if (e.target.value !== '') {
      setFiltroUf(e.target.value);
    } else {
      setFiltroUf();
    }
  }

  // Controle de paginacao
  function digitarPaginaMenos() {
    if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1);
  }
  function digitarPaginaMais() {
    if (paginaAtual !== paginas) setPaginaAtual(paginaAtual + 1);
  }
  function corIconePaginacaoIni() {
    if (paginaAtual <= 1) return '#f8f8f8';
    else return '#029f74';
  }
  function corIconePaginacaoFim() {
    if (paginas === paginaAtual) return '#f8f8f8';
    else return '#029f74';
  }
  function paginacao(p) {
    if (p === 1) return (paginaAtual - 1) * 10;
    else return paginaAtual * 10;
  }

  // Inclusao, alteracao e exclusao
  async function adicionar() {
    await history.push('cidades-cadastro');
  }
  async function editar(e) {
    await history.push(`cidades-cadastro?id=${e}`);
  }
  async function deletar(e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation>
            <p>Confirma a exclusão da cidade?</p>
            <div>
              <button
                onClick={async () => {
                  const clientes = await api.get('/cliente', {
                    params: {
                      idcidade: e,
                    },
                  });
                  if (clientes.data.length > 0) {
                    toast.error(
                      `Cidade vinculada a ${clientes.data.length} cliente(s), não é possível excluir!`
                    );
                  } else {
                    const response = await api.delete(`/cidade/${e}`);
                    if (response.status === 200) {
                      toast.success('Cidade deletada com sucesso!');
                    } else {
                      toast.error(
                        'Problemas ao deletar cidade, tente novamente!'
                      );
                    }
                    carregar();
                  }
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          </Confirmation>
        );
      },
    });
  }
  function imprimir() {
    const cabecalho = '<th>CEP</th><th>CIDADE</th><th>UF</th>';
    let conteudo = '';
    entidades.map(
      cidade =>
        (conteudo =
          conteudo +
          `<tr key=${cidade.id}> ` +
          `<td>${cidade.cep}</td>  ` +
          `<td>${cidade.cidade}</td> ` +
          `<td>${cidade.uf === null ? '' : cidade.uf}</td> ` +
          `</tr>`)
    );
    relatorio.relatorio('RELAÇÃO DE CIDADES', cabecalho, conteudo);
  }

  // Render da tela
  return (
    <Container>
      <Titulo>
        <strong>LISTAGEM DE CIDADES</strong>
        <div>
          <TituloBotao destaque={true} onClick={adicionar}>
            <div>
              <FaPlus size={18} color={'#fff'} />
            </div>
            Adicionar
          </TituloBotao>
          <TituloBotao onClick={imprimir}>
            <div>
              <FaPrint size={18} color={'#029f74'} />
            </div>
            Imprimir
          </TituloBotao>
        </div>
      </Titulo>
      <Corpo>
        <CorpoFiltro>
          <CorpoFiltroInput
            onInput={digitarCep}
            size={30}
            placeholder="Filtre pelo CEP"
            value={filtroCep}
          />
          <CorpoFiltroInput
            onInput={digitarCidade}
            size={50}
            placeholder="Filtre pela cidade"
          />
          <CorpoFiltroInput
            onInput={digitarUf}
            size={20}
            placeholder="Filtre pela UF"
          />
          <CorpoFiltroBotao onClick={carregar}>
            <TabelaTdCenter>
              <FaSearch size={18} color={'#fff'} />
            </TabelaTdCenter>
            {/**Filtrar*/}
          </CorpoFiltroBotao>
        </CorpoFiltro>
        <CorpoTabela id="tabela">
          <table>
            <thead>
              <tr>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('id');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('id', false)} />
                    <FaArrowDown size={14} color={ordenarCor('id', true)} />
                  </button>
                  Cod.
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('cidade');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('cidade', false)} />
                    <FaArrowDown size={14} color={ordenarCor('cidade', true)} />
                  </button>
                  Cidade
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('cep');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('cep', false)} />
                    <FaArrowDown size={14} color={ordenarCor('cep', true)} />
                  </button>
                  Cep
                </TabelaTh>
                <TabelaTh>
                  <button
                    onClick={() => {
                      ordenar('uf');
                    }}
                  >
                    <FaArrowUp size={14} color={ordenarCor('uf', false)} />
                    <FaArrowDown size={14} color={ordenarCor('uf', true)} />
                  </button>
                  UF
                </TabelaTh>
                <TabelaTh last={true} center={true}>
                  Ações
                </TabelaTh>
              </tr>
            </thead>
            <tbody>
              {entidades.slice(paginacao(1), paginacao(2)).map(e => (
                <tr key={e.id}>
                  <TabelaTd width={'10%'} center={true}>
                    {e.id}
                  </TabelaTd>
                  <TabelaTd width={'46%'}>{e.cidade}</TabelaTd>
                  <TabelaTd width={'20%'}>{e.cep}</TabelaTd>
                  <TabelaTd width={'12%'} center={true}>
                    {e.uf}
                  </TabelaTd>
                  <TabelaTd width={'12%'} center={true}>
                    <TabelaTdIcone
                      title="Clique para editar a cidade"
                      onClick={() => {
                        editar(e.id);
                      }}
                    >
                      <FaPencilAlt size={18} color={'#585858'} />
                    </TabelaTdIcone>
                    <TabelaTdIcone
                      title="Clique para deletar a cidade"
                      onClick={() => {
                        deletar(e.id);
                      }}
                    >
                      <FaTrash size={18} color={'#d13337'} />
                    </TabelaTdIcone>
                  </TabelaTd>
                </tr>
              ))}
            </tbody>
          </table>
        </CorpoTabela>
        <CorpoPaginacao>
          <span>{registros} registro(s) encontrado(s)</span>
          <div>
            <button onClick={digitarPaginaMenos}>
              <FaChevronCircleLeft size={26} color={corIconePaginacaoIni()} />
            </button>
            <strong>
              {paginaAtual}/{paginas}
            </strong>
            <button onClick={digitarPaginaMais}>
              <FaChevronCircleRight size={26} color={corIconePaginacaoFim()} />
            </button>
          </div>
        </CorpoPaginacao>
      </Corpo>
    </Container>
  );
}
