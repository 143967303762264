class Forms {
  populaFormulario(objeto, campo, valor) {
    let obj = {};
    for (var key in objeto) {
      obj[key] = objeto[key];
    }
    obj[campo] = valor;
    return obj;
  }
  preparaFormulario(objeto) {
    let obj = {};
    for (var key in objeto) {
      if (objeto[key] === '') {
        obj[key] = null;
      } else {
        obj[key] = objeto[key];
      }
    }
    return obj;
  }
  validaFormulario(objeto, objetoValidacao) {
    let obj = {};
    let validado = true;
    for (var key in objetoValidacao) {
      if (objeto[key] === null || objeto[key] === '') {
        validado = false;
        obj[key] = false;
      } else {
        obj[key] = true;
      }
    }
    return [obj, validado];
  }
  validaFormularioCampo(objeto, objetoValidacao, campo) {
    let obj = {};
    for (var key in objetoValidacao) {
      if (key === campo) {
        if (objeto[key] === null || objeto[key] === '') {
          obj[key] = false;
        } else {
          obj[key] = true;
        }
      } else {
        obj[key] = objetoValidacao[key];
      }
    }
    return obj;
  }
}

export default new Forms();
