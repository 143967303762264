import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #fff;
`;

export const Area = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #eaeaea;
`;
